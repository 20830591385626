.card-grid {
  @include mq('large') {
    display: flex;
  }

  > div.container {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      width: 50%;
    }

    &:nth-child(odd) {
      @include mq('large') {
        margin-right: var(--emu-common-spacing-medium);
      }
    }
  }
}
