@import '../../styles/global/_g-utilities';

.gen-form {
  a {
    color: var(--emu-semantic-colors-primary-dark);
  }

  &__inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-small);
    display: flex;
    flex-direction: column;
    gap: 57px;

    @include mq('large') {
      flex-direction: row;
      padding-right: 17px;
      padding-bottom: 61px;
      padding-left: var(--emu-common-spacing-medium);
    }

    > .container:first-child {
      @include mq('large') {
        flex-grow: 1;
        flex-basis: 655px; // as per figma
      }
    }

    > .container:last-child {
      @include mq('large') {
        flex-grow: 1;
        flex-basis: 695px; // as per figma
      }
    }
  }

  &__section {
    padding-bottom: var(--emu-common-spacing-none);

    &--form {
      padding-top: 67px;
      padding-right: 29px;
      padding-bottom: var(--emu-common-spacing-xl);
      padding-left: 29px;

      @include mq('medium') {
        padding-top: var(--emu-common-spacing-xl);
        padding-right: var(--emu-common-spacing-large);
        padding-bottom: var(--emu-common-spacing-xl);
        padding-left: var(--emu-common-spacing-large);
      }
    }
  }

  &__pretitle {
    margin-bottom: 7px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-xxs);
    }
  }

  &__title {
    max-width: 452px; // to match figma
    margin-bottom: var(--emu-common-spacing-large);
  }

  &__content {
    &-title {
      margin-bottom: 15px;

      @include mq('large') {
        margin-bottom: 17px;
      }
    }
  }

  &__form {
    &-intro {
      @include mq('medium') {
        max-width: 460px; // to match figma
      }
    }

    &-preheading {
      margin-bottom: 10px;

      .emu-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }
    }

    &-heading {
      margin-bottom: var(--emu-common-spacing-large);

      .emu-title__text {
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-common-line-heights-narrow-xl);
      }
    }

    &-desc {
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-bottom: 33px;
      }
    }

    &-submit {
      width: 100%;
      justify-content: center;

      &.emu-form-button {
        padding-top: 15px;
        padding-bottom: var(--emu-common-spacing-medium);
      }
    }
  }

  &__helper-text {
    margin-top: -6px;
    margin-bottom: 18px;
  }
}
