#contact-us {
  .title-hero {
    &__inner {
      @include mq('large', 'max-width') {
        padding-top: 26px; //to match figma
      }

      @include mq('large') {
        padding-bottom: 135px;
      }
    }
  }

  .program-contacts {
    &__inner {
      padding-top: var(--emu-common-spacing-large);
      padding-right: 37px;
      padding-bottom: var(--emu-common-spacing-large);
      padding-left: 37px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-xl);
        padding-right: 135px;
        padding-bottom: 203px; //to match figma
        padding-left: 135px;
      }
    }

    &__title {
      margin-bottom: 39px;

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-xl);
      }
    }

    &__items {
      @include mq('large') {
        display: flex;
      }

      > .container {
        @include mq('large') {
          flex: 1;
        }

        &:first-child {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-medium);
          }
        }
      }
    }

    &__item {
      padding-top: var(--emu-common-spacing-large);
      padding-bottom: var(--emu-common-spacing-large);
      border-top: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-secondary-dark);

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
        display: flex;
      }

      &--rising-star {
        .program-contacts__item-txt {
          margin-top: 33px;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }

          h2,
          h3,
          h4,
          h5 {
            margin-bottom: 13px;

            @include mq('large') {
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    &__item-txt {
      margin-top: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-xl);
      }

      h2,
      h3,
      h4,
      h5 {
        margin-bottom: 12px;

        @include mq('large') {
          margin-bottom: 9px;
        }
      }

      p {
        & + p {
          margin-top: var(--emu-common-spacing-medium); //to match figma
          line-height: 20px;
        }

        a {
          color: var(--emu-semantic-colors-primary-dark);
          font-family: var(--emu-common-font-families-serif);
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: inherit;
        }
      }
    }
  }
}
