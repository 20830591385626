#c-support {
  .teaser {
    .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero {
      .content {
        @include mq('large') {
          padding-top: 93px; //to match figma
        }

        .emu-teaser {
          &__title {
            @include mq('large') {
              max-width: 465px; //to match figma
            }
          }
        }
      }

      .content-container {
        padding-top: 25px; //to match figma
        padding-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-large); //to match figma
        }
      }
    }
  }

  .page-intro {
    &__wrapper {
      padding-right: 37px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-xl);
        padding-right: 135px;
      }
    }

    &__text {
      margin-top: var(--emu-common-spacing-medium);

      p {
        padding-right: var(--emu-common-spacing-none);
      }
    }

    &__content-wrapper {
      @include mq('large') {
        max-width: 808px; //as per figma
      }
    }
  }

  .cos {
    &__platforms {
      &-inner {
        padding-left: 9px;
        padding-right: 9px;

        @include mq('large') {
          padding-left: 15px;
          padding-right: var(--emu-common-spacing-none);
        }

        > .container {
          &:not(:last-child) {
            border-bottom: var(--emu-common-border-width-medium) solid
              var(--emu-semantic-colors-primary-500);
          }
        }
      }

      &-section-wrapper {
        padding-top: 62px;
        padding-bottom: 61px;

        &--education {
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: 65px;

          .cos__platforms {
            &-title {
              padding-bottom: var(--emu-common-spacing-medium);
            }

            &-section {
              > .container:not(:last-child) {
                margin-bottom: 62px;

                @include mq('large') {
                  margin-bottom: var(--emu-common-spacing-none);
                }
              }
            }

            &-txt {
              @include mq('large') {
                margin-top: 31px;
              }
            }
          }
        }

        &--be-tools {
          padding-bottom: 62px;

          @include mq('large') {
            padding-bottom: 61px;
          }

          .cos__platforms {
            &-section {
              > .container:not(:last-child) {
                margin-bottom: 63px;

                @include mq('large') {
                  margin-bottom: var(--emu-common-spacing-none);
                }
              }
            }

            &-heading {
              margin-bottom: 35px;

              @include mq('large') {
                margin-bottom: 33px;
              }
            }

            &-txt {
              margin-top: var(--emu-common-spacing-large);

              @include mq('large') {
                margin-top: 34px;
              }

              &--secondary {
                margin-top: var(--emu-common-spacing-medium);

                @include mq('large') {
                  margin-top: 13px; // to match figma
                }
              }
            }

            &-logo {
              padding-bottom: 3px;
            }
          }
        }
      }

      &-heading {
        margin-bottom: var(--emu-common-spacing-large);
      }

      &-section {
        @include mq('large') {
          display: flex;
        }

        > .container {
          @include mq('large') {
            flex: 1;
            margin-right: var(--emu-common-spacing-medium);
          }

          &:last-child {
            @include mq('large') {
              margin-right: 15px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 59px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-logo {
        padding-right: var(--emu-common-spacing-xs);
        padding-bottom: 5px; //to match figma
        border-bottom: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-secondary-dark);

        @include mq('large') {
          padding-right: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-xs);
        }
      }

      &-title {
        padding-bottom: 18px; //to match figma
        border-bottom: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-secondary-dark);

        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-medium);
        }

        .cmp-title__text {
          line-height: 29px; //to match figma

          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-wide-large);
          }
        }
      }

      &-txt {
        margin-top: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-top: 34px;
        }

        p {
          font-weight: inherit;

          @include mq('large') {
            letter-spacing: 0.02px; //to match figma
          }
        }

        a {
          color: var(--emu-semantic-colors-primary-dark);
          font-weight: var(--emu-common-font-weight-regular);
        }

        b {
          display: inline-block;
          margin-bottom: 5px; //to match figma
        }

        h2,
        h3,
        h4,
        h5, .emphasis {
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: 140%;
          font-weight: var(--emu-common-font-weight-bold);
          font-family: var(--emu-common-font-families-serif);
          margin-top: 15px;
          margin-bottom: var(--emu-common-spacing-xs);
        }

        &--secondary {
          margin-top: var(--emu-common-spacing-none);

          p {
            b {
              .emphasis {
                display: block;
                margin-top: 14px; // to match figma
                margin-bottom: var(--emu-common-spacing-none);

                @include mq('large') {
                  margin-top: var(--emu-common-spacing-medium);
                }
              }
            }

            .emphasis {
              a {
                display: block;
                margin-top: 11px; // to match figma
                margin-bottom: var(--emu-common-spacing-xxs); // to match figma
              }
            }

            & + p {
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &-disclaimer {
        margin-top: 61px;

        @include mq('large') {
          margin-top: 29px; //to match figma
        }

        p + p {
          margin-top: 11px;
        }

        a {
          font-weight: inherit;
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }
  }
}
