body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: BeatriceStandardLight;
  --emu-common-font-families-serif: Roboto, sans-serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1440px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-primary-light);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-primary-light);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-primary-light);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: var(--emu-common-colors-medium-brown-50);
  --emu-semantic-colors-primary-100: var(--emu-common-colors-medium-brown-100);
  --emu-semantic-colors-primary-200: var(--emu-common-colors-medium-brown-200);
  --emu-semantic-colors-primary-300: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-primary-400: var(--emu-common-colors-medium-brown-400);
  --emu-semantic-colors-primary-500: var(--emu-common-colors-light-brown-500);
  --emu-semantic-colors-primary-600: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-primary-700: var(--emu-common-colors-medium-brown-700);
  --emu-semantic-colors-primary-800: var(--emu-common-colors-medium-brown-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-secondary-50: var(--emu-common-colors-blush-50);
  --emu-semantic-colors-secondary-100: var(--emu-common-colors-blush-100);
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-blush-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-blush-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: #757575;
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: #4b4c4e;
  --emu-semantic-colors-secondary-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: #eb0000;
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-green-50: #4caf50;
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: var(--emu-common-font-families-serif);
  --emu-semantic-font-families-heading: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-mono: var(--emu-common-font-families-mono);
  --emu-semantic-font-families-beatrice-regular: BeatriceStandardRegular;
  --emu-semantic-font-families-beatrice-bold: BeatriceStandardBold;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-small);
  --emu-semantic-spacing-horizontal-narrow: 8px;
  --emu-semantic-spacing-horizontal-wide: 16px;
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-small);
  --emu-semantic-spacing-vertical-narrow: 8px;
  --emu-semantic-spacing-vertical-wide: 16px;
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 20px;
  --emu-semantic-font-sizes-narrow-large: 24px;
  --emu-semantic-font-sizes-narrow-xl: 28px;
  --emu-semantic-font-sizes-narrow-xxl: 34px;
  --emu-semantic-font-sizes-narrow-xxxl: 40px;
  --emu-semantic-font-sizes-narrow-small: 18px;
  --emu-semantic-font-sizes-narrow-xs: 16px;
  --emu-semantic-font-sizes-narrow-xxs: 14px;
  --emu-semantic-font-sizes-wide-medium: 24px;
  --emu-semantic-font-sizes-wide-large: 30px;
  --emu-semantic-font-sizes-wide-xl: 36px;
  --emu-semantic-font-sizes-wide-xxl: 45px;
  --emu-semantic-font-sizes-wide-xxxl: 64px;
  --emu-semantic-font-sizes-wide-small: 18px;
  --emu-semantic-font-sizes-wide-xs: 16px;
  --emu-semantic-font-sizes-wide-xxs: 14px;
  --emu-semantic-line-heights-narrow-large: 28.8px;
  --emu-semantic-line-heights-narrow-medium: 26px;
  --emu-semantic-line-heights-narrow-xl: 33.6px;
  --emu-semantic-line-heights-narrow-xxl: 40.8px;
  --emu-semantic-line-heights-narrow-xxxl: 48px;
  --emu-semantic-line-heights-narrow-small: 25.2px;
  --emu-semantic-line-heights-narrow-xs: 22.4px;
  --emu-semantic-line-heights-narrow-xxs: 19.6px;
  --emu-semantic-line-heights-wide-large: 36px;
  --emu-semantic-line-heights-wide-medium: 31.2px;
  --emu-semantic-line-heights-wide-xl: 43.2px;
  --emu-semantic-line-heights-wide-xxl: 54px;
  --emu-semantic-line-heights-wide-xxxl: 76.8px;
  --emu-semantic-line-heights-wide-small: 25.2px;
  --emu-semantic-line-heights-wide-xs: 22.4px;
  --emu-semantic-line-heights-wide-xxs: 19.6px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 360px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-narrow: 29px 37px 31px 37px;
  --emu-component-cards-teaser-padding-wide: 34px 37px 31px 135px;
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 100%;
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.6667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-component-lists-accordion-margin-top-narrow) var(--emu-component-lists-accordion-margin-right-narrow) var(--emu-component-lists-accordion-margin-bottom-narrow) var(--emu-component-lists-accordion-margin-left-narrow);
  --emu-component-lists-accordion-margin-wide: var(--emu-component-lists-accordion-margin-top-wide) var(--emu-component-lists-accordion-margin-right-wide) var(--emu-component-lists-accordion-margin-bottom-wide) var(--emu-component-lists-accordion-margin-left-wide);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-component-lists-accordion-item-body-padding-bottom-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-primary-light);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: 17px var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-padding-wide: 17px var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-semantic-colors-secondary-800);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: 17px 16px 17px 15px;
  --emu-component-ingredients-text-input-text-padding-wide: 17px 16px 17px 20px;
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-common-colors-light-grey-500);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 7.5px var(--emu-common-spacing-none);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 7.5px var(--emu-common-spacing-none);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-common-spacing-small) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: 24px;
  --emu-component-ingredients-radio-input-control-size-all-wide: 24px;
  --emu-component-ingredients-radio-input-control-border-radius-narrow: 15px;
  --emu-component-ingredients-radio-input-control-border-radius-wide: 15px;
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-medium) var(--emu-common-spacing-none);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-common-spacing-none) 6var(--emu-common-spacing-none) var(--emu-common-spacing-none) 0px;
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-common-spacing-small);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-semantic-colors-secondary-800);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-common-colors-black);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: 24px;
  --emu-component-ingredients-checkbox-input-control-size-all-wide: 24px;
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-common-colors-white);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-common-spacing-none);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-common-spacing-none);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-common-spacing-small);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-common-spacing-xs) var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-none);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: 18px 17px 17px 15px;
  --emu-component-ingredients-textarea-input-text-padding-wide: 18px 17px 17px 19px;
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-common-spacing-none) var(--emu-common-spacing-none) var(--emu-common-spacing-small) var(--emu-common-spacing-none);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-semantic-colors-error-50);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-semantic-colors-error-50);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / 20px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / 20px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-common-colors-dark-brown-500);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-common-colors-dark-brown-500);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-secondary-950);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-secondary-950);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-primary-dark);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-common-colors-beige-500);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-secondary-950);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-primary-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-secondary-950);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 10px 16px;
  --emu-component-actions-button-padding-wide: 10px 16px;
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: 31px;
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: 31px;
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-common-colors-light-grey-500);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-large);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-background-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-narrow: 0px;
  --emu-component-containers-container-padding-wide: 0px;
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-beige-500);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-beige-500);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  font: var(--emu-semantic-typography-narrow-body-regular);
  overflow-x: hidden !important;
}

@media (min-width: 1024px) {
  body {
    font: var(--emu-semantic-typography-narrow-body-regular);
  }
}

main {
  overflow: hidden;
}

html, body, h1, h2, h3, h4, h5, h6, p, li, a, span, button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

p, li {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}

p + p, li + li {
  margin-top: 13px;
}

sup {
  vertical-align: super;
  font-size: 60%;
  font-weight: inherit;
  line-height: 0;
  position: relative;
  top: .03em;
}

.cmp-title__text sup {
  font-size: 100%;
  top: .35em;
}

i sup {
  left: .05em;
}

.footer-in-view [data-component="container-isi-v2"] .isi-container {
  transform: translateY(100%);
}

@media (max-width: 1023px) {
  .mobile-menu-opened {
    overflow: hidden;
  }
}

.emu-consent-banner > div {
  max-width: 1170px;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

[data-component="title"][class*="u-typography--"] h1, [data-component="title"][class*="u-typography--"] h2, [data-component="title"][class*="u-typography--"] h3, [data-component="title"][class*="u-typography--"] h4, [data-component="title"][class*="u-typography--"] h5, [data-component="title"][class*="u-typography--"] h6, [data-component="title"][class*="u-typography--"] li, [data-component="title"][class*="u-typography--"] p, [data-component="title"][class*="u-typography--"] a, [data-component="title"][class*="u-typography--"] span, [data-component="text"][class*="u-typography--"] h1, [data-component="text"][class*="u-typography--"] h2, [data-component="text"][class*="u-typography--"] h3, [data-component="text"][class*="u-typography--"] h4, [data-component="text"][class*="u-typography--"] h5, [data-component="text"][class*="u-typography--"] h6, [data-component="text"][class*="u-typography--"] li, [data-component="text"][class*="u-typography--"] p, [data-component="text"][class*="u-typography--"] a, [data-component="text"][class*="u-typography--"] span {
  font: inherit;
}

.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
}

@media (min-width: 768px) {
  .u-typography--h1 {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);
}

@media (min-width: 768px) {
  .u-typography--h2 {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);
}

@media (min-width: 768px) {
  .u-typography--h3 {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);
}

@media (min-width: 768px) {
  .u-typography--h4 {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5, #start .start__rpd-teaser .cmp-teaser__description p, #error .error__content p {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

@media (min-width: 768px) {
  .u-typography--h5, #start .start__rpd-teaser .cmp-teaser__description p, #error .error__content p {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-sm {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xs {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs, #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container .emu-teaser__disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xxs, #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container .emu-teaser__disclaimer p {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

[data-component="text"][class*="u-font-family--"] h1, [data-component="text"][class*="u-font-family--"] h2, [data-component="text"][class*="u-font-family--"] h3, [data-component="text"][class*="u-font-family--"] h4, [data-component="text"][class*="u-font-family--"] h5, [data-component="text"][class*="u-font-family--"] h6, [data-component="text"][class*="u-font-family--"] li, [data-component="text"][class*="u-font-family--"] p, [data-component="text"][class*="u-font-family--"] a, [data-component="text"][class*="u-font-family--"] span, [data-component="title"][class*="u-font-family--"] h1, [data-component="title"][class*="u-font-family--"] h2, [data-component="title"][class*="u-font-family--"] h3, [data-component="title"][class*="u-font-family--"] h4, [data-component="title"][class*="u-font-family--"] h5, [data-component="title"][class*="u-font-family--"] h6, [data-component="title"][class*="u-font-family--"] li, [data-component="title"][class*="u-font-family--"] p, [data-component="title"][class*="u-font-family--"] a, [data-component="title"][class*="u-font-family--"] span {
  font-family: inherit;
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg, #start .start__rpd-teaser .cmp-teaser__description p, #error .error__content p {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

.u-container--max {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-fancy-border:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  width: 80px;
  padding-bottom: 10px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .u-fancy-border:before {
    width: 180px;
    padding-bottom: 25px;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__description {
    padding-top: 9px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__action-container .emu-button-v2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__image img {
    object-fit: contain;
    object-position: calc(50% + 3px);
    max-height: 220px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .content-container {
    padding-bottom: 17px;
    position: static;
  }
}

.u-teaser-disclaimer-text-color--dark .content-container .emu-teaser__disclaimer p {
  color: var(--emu-semantic-colors-secondary-dark);
}

.u-form-2-col {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .u-form-2-col {
    flex-direction: row;
    gap: 17px;
  }
}

.u-form-2-col .text {
  flex: 1;
}

@media (max-width: 767px) {
  .u-form-el-stacked-in-mobile {
    flex-direction: column;
    display: flex;
  }

  .u-form-el-stacked-in-mobile.emu-form-radio[data-layout="inline"], .u-form-el-stacked-in-mobile.emu-form-checkbox[data-layout="inline"] {
    gap: var(--emu-common-spacing-none);
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo svg {
  display: block;
}

#home .home__hero {
  background-position: 50% 13px;
  background-repeat: no-repeat;
  position: relative;
  background-size: 109px !important;
}

@media (min-width: 768px) {
  #home .home__hero {
    background-position: 80px;
  }
}

@media (min-width: 1024px) {
  #home .home__hero {
    background-position: 74px calc(50% + 32px);
    background-size: 345px !important;
  }
}

@media (min-width: 1440px) {
  #home .home__hero {
    background-position: calc(50vw - 646px) calc(50% + 32px);
  }
}

#home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container {
  padding-top: 28px;
  padding-bottom: 36px;
  position: static;
}

@media (min-width: 1024px) {
  #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container {
    max-width: 491px;
    padding-top: 79px;
    padding-left: 135px;
  }
}

#home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container .emu-teaser__disclaimer {
  padding: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-small);
  bottom: var(--emu-common-spacing-small);
  position: absolute;
}

@media (min-width: 1024px) {
  #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .content-container .emu-teaser__disclaimer {
    right: var(--emu-common-spacing-medium);
    bottom: var(--emu-common-spacing-medium);
  }

  #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container {
    align-self: stretch;
  }
}

#home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container a {
  pointer-events: none;
}

@media (min-width: 1024px) {
  #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container div {
    height: 100%;
  }

  #home .home__hero-teaser.emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .emu-teaser__image img {
    object-fit: cover;
    height: 100%;
  }
}

#home .home__program-inner {
  padding-top: 30px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #home .home__program-inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: 135px;
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: 135px;
  }
}

#home .home__program-spacing-block {
  border-top: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-500);
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-500);
  margin-top: 36px;
  margin-bottom: 36px;
  padding-top: 36px;
  padding-bottom: 35px;
}

@media (min-width: 1024px) {
  #home .home__program-spacing-block {
    margin-top: var(--emu-common-spacing-xl);
    margin-bottom: var(--emu-common-spacing-xl);
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
  }
}

#home .home__program-card--rising-star .brand-card__cta {
  padding-right: 26px;
}

@media (min-width: 1024px) {
  #home .home__program-card--ami > div.container:first-child, #home .home__program-card--exp > div.container:first-child {
    margin-right: var(--emu-common-spacing-medium);
  }

  #home .home__program-card--exp .brand-card__txt {
    margin-bottom: 31px;
  }
}

#home .home__program-card--exp .brand-card__cta {
  min-width: 0;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  #home .home__program-card--exp .brand-card__cta {
    min-width: 250px;
  }

  #home .home__program-promo-video {
    margin-top: var(--emu-common-spacing-medium);
  }
}

#home .home__products-inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  #home .home__products-inner {
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 48px;
    padding-left: var(--emu-common-spacing-medium);
  }
}

#home .home__products-title {
  margin-bottom: var(--emu-common-spacing-large);
}

#home .home__products-grid {
  margin-bottom: 48px;
}

#home .home__products .flippable-card__img img {
  height: 400px;
}

#home .home__products-cta-container {
  justify-content: center;
  display: flex;
}

#home .home__products__cta {
  text-wrap: nowrap;
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 1024px) {
  #home .home__products__cta {
    min-width: 365px;
  }
}

#home .home__references {
  margin-top: var(--emu-common-spacing-large);
  width: 100%;
  max-width: 1052px;
  margin-bottom: 48px;
}

#home .home__references p {
  line-height: 20px;
}

#home .home__support-teaser .teaser .emu-teaser.emu-teaser-v3 .content-container .content-middle-left {
  overflow: hidden;
}

#home .home__support-teaser-cta {
  padding-left: 54.5px;
  padding-right: 54.5px;
}

@media (min-width: 1024px) {
  #home .home__support-teaser-cta {
    padding-left: 23.5px;
    padding-right: 23.5px;
  }

  #home .home__support-teaser-cta .cmp-button__text {
    letter-spacing: -.03px;
  }

  #home .home__equity-teaser .emu-teaser .content-container {
    padding-top: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 135px;
  }
}

#home .home__equity-teaser .emu-teaser__description {
  max-width: 700px;
}

@media (min-width: 1024px) {
  #home .home__equity-teaser .emu-teaser__action-container .emu-button-v2 {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    letter-spacing: .1px;
  }
}

#rising-star .rising-star__hero .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
  padding-top: 24px;
  padding-bottom: 33px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__hero .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
    padding-top: 34px;
    padding-bottom: 31px;
  }
}

#rising-star .rising-star__intro .page-intro__wrapper {
  padding-right: 37px;
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #rising-star .rising-star__intro .page-intro__wrapper {
    padding-right: 119px;
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#rising-star .rising-star__intro .page-intro__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  max-width: 220px;
}

#rising-star .rising-star__intro .page-intro__text p {
  padding-right: var(--emu-common-spacing-none);
}

@media (max-width: 1023px) {
  #rising-star .rising-star__intro .page-intro__img {
    margin-top: 30px;
  }
}

#rising-star .rising-star__intro .page-intro__img img {
  width: 168px;
}

@media (min-width: 1024px) {
  #rising-star .rising-star__intro .page-intro__img img {
    width: 266px;
  }
}

@media (min-width: 768px) {
  #rising-star .rising-star__programs .catalog__inner {
    padding-top: 63px;
  }
}

#rising-star .rising-star__programs .catalog__inner .intro-group__item-spacing-block {
  margin-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #rising-star .rising-star__programs .catalog__inner .intro-group__item-spacing-block {
    margin-right: 15px;
  }
}

#rising-star .rising-star__programs .catalog__items-container {
  margin-bottom: 65px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__programs .catalog__items-container {
    margin-bottom: 48px;
  }
}

#rising-star .rising-star__programs .catalog__title {
  margin-bottom: 66px;
}

#rising-star .rising-star__programs .catalog__section-title {
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__programs .catalog__section-title {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

#rising-star .rising-star__programs .catalog__disc-txt {
  padding-right: var(--emu-common-spacing-none);
}

#rising-star .rising-star__programs .catalog__disc-txt p + p {
  margin-top: 12px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__programs .catalog__disc-txt p + p {
    margin-top: 10px;
  }
}

@media (min-width: 1024px) {
  #rising-star .rising-star__programs .catalog__kit-section {
    padding-right: 15px;
  }

  #rising-star .rising-star__programs .catalog__kit-section > .image {
    flex-shrink: 0;
  }

  #rising-star .rising-star__programs .catalog__kit-desc {
    width: 100%;
    max-width: 895px;
  }
}

#rising-star .rising-star__programs .catalog__kit-desc {
  letter-spacing: -.14px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__programs .catalog__kit-desc {
    letter-spacing: normal;
  }
}

#rising-star .rising-star__programs-separator {
  margin-right: var(--emu-common-spacing-small);
  margin-left: var(--emu-common-spacing-small);
  height: 2px;
}

@media (min-width: 1024px) {
  #rising-star .rising-star__programs-separator {
    margin-left: 15px;
    margin-right: 15px;
  }
}

#rising-star .rising-star__enroll .gen-form__inner {
  padding-bottom: var(--emu-common-spacing-xl);
  gap: 61px;
}

@media (min-width: 1024px) {
  #rising-star .rising-star__enroll .gen-form__inner {
    gap: 62px;
    padding-bottom: 62px;
  }

  #rising-star .rising-star__enroll .gen-form__inner > .container:first-child {
    flex-basis: 648px;
  }
}

#rising-star .rising-star__enroll .gen-form__title {
  margin-bottom: 34px;
}

#rising-star .rising-star__enroll .gen-form__sub-title {
  padding-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #rising-star .rising-star__enroll .gen-form__sub-title {
    padding-bottom: 31px;
  }
}

#rising-star .rising-star__enroll .gen-form__content-block {
  padding-bottom: var(--emu-common-spacing-large);
}

#rising-star .rising-star__enroll .gen-form__content-block--step-2, #rising-star .rising-star__enroll .gen-form__content-block--step-5 {
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  #rising-star .rising-star__enroll .gen-form__content-block--step-2, #rising-star .rising-star__enroll .gen-form__content-block--step-5 {
    padding-bottom: 31px;
  }
}

#rising-star .rising-star__enroll .gen-form__content-block--text-spacing {
  letter-spacing: .01px;
}

#rising-star .rising-star__enroll .gen-form__content-title {
  margin-bottom: var(--emu-common-spacing-small);
}

#rising-star .rising-star__enroll .gen-form__content-title .emphasis {
  color: var(--emu-common-colors-medium-brown-500);
}

#rising-star .rising-star__enroll .gen-form__section--content .container:last-child .gen-form__content-block {
  padding-bottom: var(--emu-common-spacing-none);
}

#rising-star .rising-star__enroll .gen-form__section--form {
  padding-top: 65px;
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: 29px;
  padding-right: 29px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__section--form {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-large);
  }
}

#rising-star .rising-star__enroll .gen-form__form-heading {
  padding-bottom: var(--emu-common-spacing-xxs);
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-heading {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#rising-star .rising-star__enroll .gen-form__form-help-message {
  margin-top: -8px;
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-help-message {
    max-width: 87%;
  }
}

#rising-star .rising-star__enroll .gen-form__form-main {
  padding-bottom: 33px;
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-text__info-text {
  margin-top: 10px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-main .emu-form-text__info-text {
    margin-top: 9px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-textarea__textarea {
  min-height: 210px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-main .emu-form-textarea__textarea {
    min-height: 120px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-radio {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 15px;
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-dropdown {
  margin-left: var(--emu-common-spacing-none);
  margin-bottom: 15px;
  margin-right: var(--emu-common-spacing-none);
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-dropdown .emu-dropdown-menu__button {
  padding-right: 24px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-main .emu-form-dropdown .emu-dropdown-menu__button {
    padding-right: 14px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-checkbox {
  margin-top: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-main .emu-form-checkbox {
    margin-top: 17px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-main .emu-form-recaptcha {
  margin-top: 21px;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-main .emu-form-recaptcha {
    margin-top: 17px;
    margin-bottom: 33px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-location-fields .text {
  flex: 0 0 50.5%;
}

@media (min-width: 768px) {
  #rising-star .rising-star__enroll .gen-form__form-location-fields .options {
    flex: 1;
    max-width: 140px;
  }
}

#rising-star .rising-star__enroll .gen-form__form-location-fields .text:last-child {
  flex: 1;
  max-width: 140px;
}

#rising-star .rising-star__enroll .gen-form__form-location-fields .emu-form-dropdown .emu-dropdown-menu__button {
  padding-right: 23px;
}

@media (max-width: 1023px) {
  #contact-us .title-hero__inner {
    padding-top: 26px;
  }
}

@media (min-width: 1024px) {
  #contact-us .title-hero__inner {
    padding-bottom: 135px;
  }
}

#contact-us .program-contacts__inner {
  padding-top: var(--emu-common-spacing-large);
  padding-right: 37px;
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: 37px;
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: 203px;
    padding-left: 135px;
    padding-right: 135px;
  }
}

#contact-us .program-contacts__title {
  margin-bottom: 39px;
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__title {
    margin-bottom: var(--emu-common-spacing-xl);
  }

  #contact-us .program-contacts__items {
    display: flex;
  }

  #contact-us .program-contacts__items > .container {
    flex: 1;
  }

  #contact-us .program-contacts__items > .container:first-child {
    margin-right: var(--emu-common-spacing-medium);
  }
}

#contact-us .program-contacts__item {
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: var(--emu-common-spacing-large);
  border-top: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-secondary-dark);
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__item {
    padding-bottom: var(--emu-common-spacing-none);
    display: flex;
  }
}

#contact-us .program-contacts__item--rising-star .program-contacts__item-txt {
  margin-top: 33px;
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__item--rising-star .program-contacts__item-txt {
    margin-top: var(--emu-common-spacing-none);
  }
}

#contact-us .program-contacts__item--rising-star .program-contacts__item-txt h2, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h3, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h4, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h5 {
  margin-bottom: 13px;
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h2, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h3, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h4, #contact-us .program-contacts__item--rising-star .program-contacts__item-txt h5 {
    margin-bottom: 9px;
  }
}

#contact-us .program-contacts__item-txt {
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__item-txt {
    margin-top: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-xl);
  }
}

#contact-us .program-contacts__item-txt h2, #contact-us .program-contacts__item-txt h3, #contact-us .program-contacts__item-txt h4, #contact-us .program-contacts__item-txt h5 {
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  #contact-us .program-contacts__item-txt h2, #contact-us .program-contacts__item-txt h3, #contact-us .program-contacts__item-txt h4, #contact-us .program-contacts__item-txt h5 {
    margin-bottom: 9px;
  }
}

#contact-us .program-contacts__item-txt p + p {
  margin-top: var(--emu-common-spacing-medium);
  line-height: 20px;
}

#contact-us .program-contacts__item-txt p a {
  color: var(--emu-semantic-colors-primary-dark);
  font-family: var(--emu-common-font-families-serif);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: inherit;
}

@media (min-width: 1024px) {
  #c-support .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content {
    padding-top: 93px;
  }

  #c-support .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content .emu-teaser__title {
    max-width: 465px;
  }
}

#c-support .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
  padding-top: 25px;
  padding-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #c-support .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
    padding-top: var(--emu-common-spacing-large);
  }
}

#c-support .page-intro__wrapper {
  padding-right: 37px;
}

@media (min-width: 1024px) {
  #c-support .page-intro__wrapper {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: 135px;
  }
}

#c-support .page-intro__text {
  margin-top: var(--emu-common-spacing-medium);
}

#c-support .page-intro__text p {
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #c-support .page-intro__content-wrapper {
    max-width: 808px;
  }
}

#c-support .cos__platforms-inner {
  padding-left: 9px;
  padding-right: 9px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-inner {
    padding-left: 15px;
    padding-right: var(--emu-common-spacing-none);
  }
}

#c-support .cos__platforms-inner > .container:not(:last-child) {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-500);
}

#c-support .cos__platforms-section-wrapper {
  padding-top: 62px;
  padding-bottom: 61px;
}

#c-support .cos__platforms-section-wrapper--education {
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: 65px;
}

#c-support .cos__platforms-section-wrapper--education .cos__platforms-title {
  padding-bottom: var(--emu-common-spacing-medium);
}

#c-support .cos__platforms-section-wrapper--education .cos__platforms-section > .container:not(:last-child) {
  margin-bottom: 62px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--education .cos__platforms-section > .container:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-none);
  }

  #c-support .cos__platforms-section-wrapper--education .cos__platforms-txt {
    margin-top: 31px;
  }
}

#c-support .cos__platforms-section-wrapper--be-tools {
  padding-bottom: 62px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--be-tools {
    padding-bottom: 61px;
  }
}

#c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-section > .container:not(:last-child) {
  margin-bottom: 63px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-section > .container:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-heading {
  margin-bottom: 35px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-heading {
    margin-bottom: 33px;
  }
}

#c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-txt {
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-txt {
    margin-top: 34px;
  }
}

#c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-txt--secondary {
  margin-top: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-txt--secondary {
    margin-top: 13px;
  }
}

#c-support .cos__platforms-section-wrapper--be-tools .cos__platforms-logo {
  padding-bottom: 3px;
}

#c-support .cos__platforms-heading {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section {
    display: flex;
  }

  #c-support .cos__platforms-section > .container {
    margin-right: var(--emu-common-spacing-medium);
    flex: 1;
  }

  #c-support .cos__platforms-section > .container:last-child {
    margin-right: 15px;
  }
}

#c-support .cos__platforms-section > .container:not(:last-child) {
  margin-bottom: 59px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-section > .container:not(:last-child) {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

#c-support .cos__platforms-logo {
  padding-right: var(--emu-common-spacing-xs);
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-secondary-dark);
  padding-bottom: 5px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-logo {
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-xs);
  }
}

#c-support .cos__platforms-title {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-secondary-dark);
  padding-bottom: 18px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-title {
    padding-bottom: var(--emu-common-spacing-medium);
  }
}

#c-support .cos__platforms-title .cmp-title__text {
  line-height: 29px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-title .cmp-title__text {
    line-height: var(--emu-semantic-line-heights-wide-large);
  }
}

#c-support .cos__platforms-txt {
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-txt {
    margin-top: 34px;
  }
}

#c-support .cos__platforms-txt p {
  font-weight: inherit;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-txt p {
    letter-spacing: .02px;
  }
}

#c-support .cos__platforms-txt a {
  color: var(--emu-semantic-colors-primary-dark);
  font-weight: var(--emu-common-font-weight-regular);
}

#c-support .cos__platforms-txt b {
  margin-bottom: 5px;
  display: inline-block;
}

#c-support .cos__platforms-txt h2, #c-support .cos__platforms-txt h3, #c-support .cos__platforms-txt h4, #c-support .cos__platforms-txt h5, #c-support .cos__platforms-txt .emphasis {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 140%;
  font-weight: var(--emu-common-font-weight-bold);
  font-family: var(--emu-common-font-families-serif);
  margin-top: 15px;
  margin-bottom: var(--emu-common-spacing-xs);
}

#c-support .cos__platforms-txt--secondary {
  margin-top: var(--emu-common-spacing-none);
}

#c-support .cos__platforms-txt--secondary p b .emphasis {
  margin-top: 14px;
  margin-bottom: var(--emu-common-spacing-none);
  display: block;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-txt--secondary p b .emphasis {
    margin-top: var(--emu-common-spacing-medium);
  }
}

#c-support .cos__platforms-txt--secondary p .emphasis a {
  margin-top: 11px;
  margin-bottom: var(--emu-common-spacing-xxs);
  display: block;
}

#c-support .cos__platforms-txt--secondary p + p {
  margin-top: var(--emu-common-spacing-none);
}

#c-support .cos__platforms-disclaimer {
  margin-top: 61px;
}

@media (min-width: 1024px) {
  #c-support .cos__platforms-disclaimer {
    margin-top: 29px;
  }
}

#c-support .cos__platforms-disclaimer p + p {
  margin-top: 11px;
}

#c-support .cos__platforms-disclaimer a {
  font-weight: inherit;
  color: var(--emu-semantic-colors-primary-dark);
}

#education .teaser .u-teaser--as-hero .content-container {
  padding-top: 25px;
}

@media (max-width: 1023px) {
  #education .teaser .u-teaser--as-hero .content-container {
    padding-bottom: var(--emu-common-spacing-large);
  }
}

@media (min-width: 1200px) {
  #education .teaser .u-teaser--as-hero .content-container {
    padding: var(--emu-component-cards-teaser-padding-wide);
  }
}

@media (min-width: 768px) {
  #education .teaser .emu-teaser__image .cmp-image img {
    width: auto;
  }
}

@media (min-width: 1024px) {
  #education .page-intro--with-logo .page-intro__wrapper {
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: 75px;
  }
}

#education .page-intro--with-logo .page-intro__img img {
  width: 100%;
  max-width: 45%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #education .page-intro--with-logo .page-intro__img img {
    margin-right: var(--emu-common-spacing-none);
    max-width: 85%;
  }
}

#education .page-intro__text.cmp-text .emphasis {
  margin-bottom: 14px;
}

#education .page-intro__content-block-spacing-block {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-light);
}

@media (max-width: 1023px) {
  #education .page-intro__content-block-spacing-block {
    padding-bottom: 31px;
  }

  #education .page-intro__cta {
    margin-top: 30px;
  }
}

#error .title-hero__inner {
  padding-top: 25px;
}

@media (min-width: 1024px) {
  #error .title-hero__inner {
    padding-top: 202px;
  }
}

#error .error__content {
  margin-bottom: var(--emu-common-spacing-medium);
}

#error .error__content-wrapper {
  padding-left: 37px;
  padding-right: 37px;
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: 118px;
}

@media (min-width: 1024px) {
  #error .error__content-wrapper {
    padding-left: 135px;
    padding-right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: 229px;
  }

  #error .error__content-inner {
    width: 808px;
  }
}

#error .error__disc {
  margin-bottom: var(--emu-common-spacing-large);
}

#error .error__disc p {
  font-weight: inherit;
}

#error .error__cta {
  padding: 17px 55.5px;
}

@media (min-width: 1024px) {
  #error .error-500 .title-hero__inner {
    padding-top: 175px;
    padding-bottom: 112px;
  }
}

#getting-started .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
  padding-top: 25px;
}

@media (min-width: 1024px) {
  #getting-started .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .content-container {
    padding-top: 35px;
  }
}

#getting-started .page-intro__wrapper {
  padding-right: 37px;
}

@media (min-width: 1024px) {
  #getting-started .page-intro__wrapper {
    padding-top: var(--emu-common-spacing-xl);
  }

  #getting-started .page-intro__wrapper > .container:first-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

#getting-started .page-intro__title .cmp-title__text {
  color: var(--emu-semantic-colors-primary-900);
}

@media (min-width: 1024px) {
  #getting-started .page-intro__content-wrapper {
    max-width: 808px;
  }
}

#getting-started .intro-group .page-intro__wrapper {
  padding: var(--emu-common-spacing-none);
}

#getting-started .intro-group .page-intro__title {
  margin-bottom: 18px;
}

#getting-started .intro-group .page-intro__title .cmp-title__text {
  line-height: 41px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro__title .cmp-title__text {
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    white-space: nowrap;
    margin-right: var(--emu-common-spacing-large);
  }
}

#getting-started .intro-group .page-intro__text p {
  letter-spacing: .01px;
  line-height: 25px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro__text p {
    letter-spacing: normal;
  }
}

#getting-started .intro-group .page-intro__text p + p {
  margin-top: 15px;
}

#getting-started .intro-group .page-intro__text ul {
  margin-top: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-medium);
  padding-left: 27px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro__text ul {
    padding-left: 26px;
  }

  #getting-started .intro-group .page-intro__text li {
    line-height: 25px;
  }
}

#getting-started .intro-group .page-intro__text li:before {
  left: -16px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro__content-wrapper {
    margin-top: var(--emu-common-spacing-small);
    margin-right: var(--emu-common-spacing-small);
  }
}

#getting-started .intro-group .page-intro__content-block-spacing-block {
  padding-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-none);
  border-bottom: none;
}

#getting-started .intro-group .page-intro__content-block-subtitle {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro__content-block-subtitle .cmp-title__text {
    line-height: 31px;
  }
}

#getting-started .intro-group .page-intro .text-cta {
  padding-top: var(--emu-common-spacing-large);
  padding-right: 29px;
  padding-bottom: var(--emu-common-spacing-large);
  margin-top: var(--emu-common-spacing-medium);
  padding-left: 29px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro .text-cta {
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
    display: flex;
  }
}

#getting-started .intro-group .page-intro .text-cta__txt p {
  line-height: 25px;
}

#getting-started .intro-group .page-intro .text-cta__txt p + p {
  letter-spacing: -.12px;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro .text-cta__txt p + p {
    letter-spacing: normal;
  }
}

#getting-started .intro-group .page-intro .text-cta__txt p a {
  color: var(--emu-semantic-colors-primary-dark);
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro .text-cta .button {
    margin-left: var(--emu-common-spacing-large);
    flex-shrink: 0;
  }
}

#getting-started .intro-group .page-intro .text-cta .button .emu-button-v2.emu-button__primary-outline {
  min-height: 54px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#getting-started .intro-group .page-intro .text-cta .button .text-cta__cta {
  margin-top: var(--emu-common-spacing-large);
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 228px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro .text-cta .button .text-cta__cta {
    margin-top: var(--emu-common-spacing-none);
    min-width: 230px;
    max-width: none;
  }
}

#getting-started .intro-group .page-intro .text-cta--volbella .text-cta__txt p + p {
  margin-top: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #getting-started .intro-group .page-intro .text-cta--volbella .text-cta__txt p + p {
    margin-top: 10px;
  }
}

#getting-started .intro-group__item-spacing-block {
  padding-bottom: 61px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group__item-spacing-block {
    padding-bottom: var(--emu-common-spacing-xl);
  }

  #getting-started .intro-group__item-spacing-block--step-3, #getting-started .intro-group__item-spacing-block--step-5 {
    padding-bottom: 62px;
  }
}

#getting-started .intro-group__item-spacing-block--step-4 {
  padding-bottom: 63px;
}

@media (min-width: 1024px) {
  #getting-started .intro-group .greeting-contact {
    margin-top: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (max-width: 1023px) {
  #getting-started .intro-group .greeting-contact .cmp-title__text {
    line-height: 34px;
  }
}

#getting-started .intro-group .greeting-contact .cmp-title__text i {
  font-style: normal;
  display: inline-block;
}

#getting-started .intro-group .greeting-contact .cmp-text {
  margin-top: var(--emu-common-spacing-large);
}

#getting-started .intro-group .greeting-contact .cmp-text a {
  color: var(--emu-semantic-colors-primary-dark);
}

#start .page-intro__wrapper {
  padding-top: 34px;
  padding-right: 37px;
}

@media (min-width: 1024px) {
  #start .page-intro__wrapper {
    padding-top: 65px;
    padding-right: 137px;
  }
}

#start .page-intro__text p {
  padding-right: var(--emu-common-spacing-none);
  line-height: 25px;
}

#start .page-intro__text u {
  text-underline-offset: 2px;
}

@media (min-width: 1024px) {
  #start .page-intro__text u {
    letter-spacing: .2px;
  }
}

@media (max-width: 1023px) {
  #start .start__hero .content-container {
    padding-top: 25px;
  }
}

#start .start__rpd-inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #start .start__rpd-inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: 135px;
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: 135px;
  }
}

#start .start__rpd-content {
  padding-left: 29px;
  padding-right: 29px;
}

@media (min-width: 1024px) {
  #start .start__rpd-content {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#start .start__rpd-title {
  margin-bottom: var(--emu-common-spacing-large);
}

#start .start__rpd-txt {
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  #start .start__rpd-txt {
    margin-bottom: 12px;
  }
}

#start .start__rpd-txt ul {
  padding-left: 17px;
}

#start .start__rpd-txt li {
  padding-left: var(--emu-common-spacing-small);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  position: relative;
}

@media (min-width: 1024px) {
  #start .start__rpd-txt li {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
    line-height: 32px;
  }
}

#start .start__rpd-txt li + li {
  margin-top: 14px;
}

@media (min-width: 1024px) {
  #start .start__rpd-txt li + li {
    margin-top: 13px;
  }
}

#start .start__rpd-txt li:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 11px;
  left: -16px;
}

@media (min-width: 1024px) {
  #start .start__rpd-txt li:before {
    top: 15px;
    left: -15px;
  }
}

#start .start__rpd-disc {
  margin-bottom: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  #start .start__rpd-disc {
    margin-bottom: 54px;
  }
}

#start .start__rpd-teaser .cmp-teaser__title {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
}

@media (min-width: 1024px) {
  #start .start__rpd-teaser .cmp-teaser__title {
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#start .start__rpd-teaser .cmp-teaser__description p {
  display: block !important;
}

#start .start__rpd-teaser .cmp-teaser__description p + p {
  margin-top: 14px;
}

#start .start__rpd-teaser .emu-teaser__description {
  padding-top: 17px;
}

@media (min-width: 1024px) {
  #start .start__rpd-teaser .emu-teaser__description {
    padding-top: var(--emu-common-spacing-medium);
  }
}

#start .start__rpd-teaser .emu-teaser__subtext {
  letter-spacing: -.15px;
  padding-top: 17px;
}

@media (min-width: 1024px) {
  #start .start__rpd-teaser .emu-teaser__subtext {
    letter-spacing: normal;
  }
}

#start .start__rpd-teaser .emu-teaser__disclaimer {
  padding-top: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  letter-spacing: -.15px;
}

@media (min-width: 1024px) {
  #start .start__rpd-teaser .emu-teaser__disclaimer {
    letter-spacing: normal;
  }
}

#start .start__rpd-teaser .emu-teaser__disclaimer p {
  white-space: nowrap;
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
}

#start .start__rpd-teaser .content-container {
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: 63px;
  padding-left: 29px;
  padding-right: 30px;
}

@media (min-width: 1024px) {
  #start .start__rpd-teaser .content-container {
    padding-right: 121px;
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: 118px;
  }
}

#start .start__ami-banner-inner {
  padding-top: 52px;
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  #start .start__ami-banner-inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-medium);
    flex-direction: row;
    align-items: center;
    gap: 22px;
    display: flex;
  }
}

#start .start__ami-banner-inner > .container:first-child {
  flex: 1;
}

#start .start__ami-banner-section--content {
  margin-top: 13px;
}

#start .start__ami-banner-pretitle {
  margin-bottom: 9px;
}

@media (min-width: 768px) {
  #start .start__ami-banner-pretitle {
    margin-bottom: var(--emu-common-spacing-medium);
    margin-top: 18px;
  }
}

#start .start__ami-banner-title {
  margin-bottom: var(--emu-common-spacing-medium);
}

#start .start__course-diff-inner {
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  #start .start__course-diff-inner {
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 62px;
  }

  #start .start__course-diff-section-wrapper {
    margin-right: var(--emu-common-spacing-medium);
    display: flex;
  }

  #start .start__course-diff-section--left {
    margin-right: 15px;
  }

  #start .start__course-diff-section--right .start__course-diff-item-wrapper > .container:first-child {
    margin-right: var(--emu-common-spacing-medium);
  }
}

#start .start__course-diff-title {
  padding-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  #start .start__course-diff-title {
    border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-secondary-dark);
  }
}

#start .start__course-diff-subtitle {
  margin-bottom: 17px;
}

@media (min-width: 1024px) {
  #start .start__course-diff-subtitle {
    min-height: 62px;
  }
}

#start .start__course-diff-txt p + p {
  margin-top: 13px;
}

#start .start__course-diff-txt a {
  color: var(--emu-semantic-colors-primary-dark);
  font-weight: var(--emu-common-font-weight-regular);
  line-height: 20px;
  display: inline-block;
}

#start .start__course-diff-item-disc {
  padding-top: 13px;
  padding-bottom: 43px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  #start .start__course-diff-item-disc {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

#start .start__course-diff-item-disc ul {
  margin-top: 9px;
  margin-bottom: 11px;
  padding-left: 21px;
  list-style: none;
}

@media (min-width: 1024px) {
  #start .start__course-diff-item-disc ul {
    margin-top: 10px;
  }
}

#start .start__course-diff-item-disc li {
  position: relative;
}

#start .start__course-diff-item-disc li + li {
  margin-top: var(--emu-common-spacing-none);
}

#start .start__course-diff-item-disc li:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 7px;
  left: -12px;
}

@media (min-width: 1024px) {
  #start .start__course-diff-item-wrapper {
    display: flex;
  }
}

#start .start__course-diff-item {
  padding-top: var(--emu-common-spacing-large);
  border-top: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-secondary-dark);
  padding-bottom: 61px;
}

@media (min-width: 1024px) {
  #start .start__course-diff-item {
    border-top: none;
    width: 100%;
    max-width: 459px;
  }
}

#start .start__course-diff-item--ami {
  padding-bottom: var(--emu-common-spacing-none);
}

#start .start__course-diff-disc {
  margin-top: var(--emu-common-spacing-xxs);
}

@media (min-width: 1024px) {
  #start .start__course-diff-disc {
    margin-top: 28px;
  }
}

#start .start__course-diff-disc a {
  color: var(--emu-semantic-colors-primary-dark);
  display: inline-block;
}

@media (min-width: 1024px) {
  .brand-card {
    flex-direction: column;
    height: 100%;
    display: flex;
  }
}

@media (max-width: 1023px) {
  .brand-card--reverse-in-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .brand-card__asset {
    height: 100%;
  }
}

.brand-card__img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

@media (max-width: 1023px) {
  .brand-card__img img {
    width: 100%;
    display: block;
  }
}

.brand-card__content {
  padding-top: 33px;
  padding-right: 30px;
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: 29px;
}

@media (min-width: 1024px) {
  .brand-card__content {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: 63px;
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-xl);
    flex-direction: column;
    height: 100%;
    display: flex;
  }
}

.brand-card__title {
  margin-bottom: 9px;
}

.brand-card__txt {
  margin-bottom: 31px;
}

@media (min-width: 1024px) {
  .brand-card__txt {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.brand-card__txt p, .brand-card__txt li {
  font-weight: inherit;
  line-height: 25px;
}

.brand-card .button .emu-button-v2.emu-button-v2.emu-button-v2 {
  padding-top: var(--emu-common-spacing-medium);
  padding-right: 24px;
  padding-bottom: var(--emu-common-spacing-medium);
  padding-left: 24px;
}

.brand-card__cta {
  justify-content: center;
}

@media (min-width: 1024px) {
  .brand-card__cta {
    min-width: 300px;
  }
}

.brand-card--desk-half-split {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .brand-card--desk-half-split {
    flex-direction: row;
  }

  .brand-card--desk-half-split > div.container {
    flex: 1;
  }

  .brand-card--desk-half-split .brand-card__content {
    justify-content: center;
    padding: 61px 75px 62px 48px;
  }
}

.brand-card--desk-half-split .brand-card__cta {
  min-width: 250px;
}

.text-theme-light .button .emu-button-v2.emu-button-v2.emu-button__secondary-outline.emu-button {
  background-color: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  border-color: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  border-radius: var(--emu-component-actions-button-border-radius-secondary-outline);
  border-width: var(--emu-component-actions-button-border-width-secondary-outline);
  color: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  outline-color: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
}

.text-theme-light .button .emu-button-v2.emu-button-v2.emu-button__secondary-outline.emu-button:focus {
  background-color: var(--emu-component-actions-button-color-background-secondary-outline-focus-light);
  border-color: var(--emu-component-actions-button-color-border-secondary-outline-focus-light);
  color: var(--emu-component-actions-button-text-color-secondary-outline-focus-light);
  outline-color: var(--emu-component-actions-button-color-outline-color-secondary-outline-focus-light);
}

.text-theme-light .button .emu-button-v2.emu-button-v2.emu-button__secondary-outline.emu-button:hover {
  background-color: var(--emu-component-actions-button-color-background-secondary-outline-hover-light);
  border-color: var(--emu-component-actions-button-color-border-secondary-outline-hover-light);
  color: var(--emu-component-actions-button-text-color-secondary-outline-hover-light);
  outline-color: var(--emu-component-actions-button-color-outline-color-secondary-outline-hover-light);
}

.text-theme-light .button .emu-button-v2.emu-button-v2.emu-button__secondary-outline.emu-button:active {
  background-color: var(--emu-component-actions-button-color-background-secondary-outline-active-light);
  border-color: var(--emu-component-actions-button-color-border-secondary-outline-active-light);
  color: var(--emu-component-actions-button-text-color-secondary-outline-active-light);
  outline-color: var(--emu-component-actions-button-color-outline-color-secondary-outline-active-light);
  outline-style: solid;
  outline-width: var(--emu-component-actions-button-outline-width-secondary-outline);
}

@media (min-width: 1024px) {
  .card-grid {
    display: flex;
  }
}

.card-grid > div.container {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .card-grid > div.container {
    width: 50%;
  }

  .card-grid > div.container:nth-child(odd) {
    margin-right: var(--emu-common-spacing-medium);
  }
}

.carousel [data-component="carousel-v2"] .tns-nav {
  padding-top: 23px;
  left: 46.9%;
}

@media (min-width: 1024px) {
  .carousel [data-component="carousel-v2"] .tns-nav {
    padding-top: 24px;
    left: 48.15%;
  }
}

.carousel [data-component="carousel-v2"] .tns-nav button {
  display: block !important;
}

.carousel [data-component="carousel-v2"] .tns-nav button, .carousel [data-component="carousel-v2"] .tns-nav button.tns-nav-active {
  background-color: var(--emu-component-containers-carousel-indicators-dots-color-background-default-light);
  border-color: var(--emu-component-containers-carousel-indicators-dots-color-border-default-light);
}

.carousel [data-component="carousel-v2"] .tns-nav button.js-nav-active {
  background-color: var(--emu-component-containers-carousel-indicators-dots-color-background-active-light);
  border-color: var(--emu-component-containers-carousel-indicators-dots-color-border-active-light);
}

.carousel [data-component="carousel-v2"] .tns-carousel.transform-none {
  transform: none !important;
}

.carousel [data-component="carousel-v2"] .emu-carousel__action {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@media (min-width: 1024px) {
  .carousel [data-component="carousel-v2"] .emu-carousel__action {
    width: 80px;
    height: 80px;
  }
}

.carousel [data-component="carousel-v2"] .emu-carousel__action:after {
  content: "";
  background-image: url("resources/images/arrow-button.svg");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel [data-component="carousel-v2"] .emu-carousel__action .emu-carousel__action-icon {
  display: none;
}

.carousel [data-component="carousel-v2"] .emu-carousel__action-prev:after {
  transform: translate(-50%, -50%)rotate(180deg);
}

.carousel [data-component="carousel-v2"] .emu-carousel__action:disabled {
  border-color: var(--emu-common-colors-beige-500);
}

.carousel [data-component="carousel-v2"] .emu-carousel__action:disabled:after {
  background-image: url("resources/images/arrow-button-disabled.svg");
}

.carousel [data-component="carousel-v2"] .emu-carousel__action:disabled.emu-carousel__action-prev:after {
  transform: translate(-50%, -50%)rotate(0);
}

.carousel [data-component="carousel-v2"] .emu-carousel__action:disabled.emu-carousel__action-next:after {
  transform: translate(-50%, -50%)rotate(180deg);
}

.catalog__inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .catalog__inner {
    padding-left: 15px;
  }
}

.catalog__title {
  margin-bottom: var(--emu-common-spacing-xl);
}

.catalog__section-title {
  padding-right: var(--emu-common-spacing-small);
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  .catalog__section-title {
    padding-right: var(--emu-common-spacing-medium);
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.catalog__section-subtitle {
  margin-bottom: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .catalog__section-subtitle {
    padding-right: var(--emu-common-spacing-medium);
    max-width: 1171px;
  }
}

.catalog__items-container {
  margin-bottom: var(--emu-common-spacing-xl);
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .catalog__items-container {
    justify-content: left;
  }
}

.catalog__items-container > .container {
  margin-right: var(--emu-common-spacing-small);
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .catalog__items-container > .container {
    margin-right: var(--emu-common-spacing-medium);
  }
}

.catalog__items-container > .container:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (max-width: 1023px) {
  .catalog__items-container > .container:last-child {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.catalog__item {
  width: 100%;
  max-width: 344px;
  min-height: 330px;
  padding: 42px 29px;
}

@media (min-width: 1024px) {
  .catalog__item {
    max-width: 339px;
  }
}

@media (min-width: 1440px) {
  .catalog__item {
    max-width: 340px;
  }
}

.catalog__item-img {
  justify-content: center;
  align-items: center;
  height: 111px;
  margin-bottom: 31px;
  display: flex;
}

@media (min-width: 1024px) {
  .catalog__item-img {
    height: 106px;
    margin-bottom: 34px;
  }
}

.catalog__item-img img {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .catalog__item-txt {
    padding-left: var(--emu-common-spacing-xxs);
    padding-right: var(--emu-common-spacing-xxs);
  }
}

.catalog__item-txt p {
  font-weight: inherit;
}

.catalog__item-txt-volume {
  margin-top: 10px;
}

@media (min-width: 1024px) {
  .catalog__item-txt-volume {
    padding-left: var(--emu-common-spacing-xxs);
    padding-right: var(--emu-common-spacing-xxs);
  }
}

.catalog__disc-txt {
  max-width: 1171px;
  padding-right: 9px;
}

@media (min-width: 1024px) {
  .catalog__disc-txt {
    padding-right: var(--emu-common-spacing-none);
  }

  .catalog__disc-txt p + p {
    margin-top: 10px;
  }
}

.catalog__kit-section {
  margin-top: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .catalog__kit-section {
    padding-right: var(--emu-common-spacing-none);
    gap: 42px;
    display: flex;
  }
}

.catalog__kit-img {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  .catalog__kit-img {
    margin-bottom: var(--emu-common-spacing-none);
  }

  .catalog__kit-desc {
    width: 895px;
  }
}

[data-component="container-isi-v2"] {
  position: relative;
}

[data-component="container-isi-v2"].isi-scrollable .isi-container {
  z-index: var(--emu-common-other-z-index-isi);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div:first-child > .container {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

[data-component="container-isi-v2"] i {
  letter-spacing: -.2px;
}

[data-component="container-isi-v2"] .gen-isi__container {
  border-top: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner {
  padding: var(--emu-component-containers-isi-header-padding-top-narrow) var(--emu-component-containers-isi-header-padding-right-narrow) var(--emu-component-containers-isi-header-padding-bottom-narrow) var(--emu-component-containers-isi-header-padding-left-narrow);
  align-items: normal;
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner {
    padding: var(--emu-component-containers-isi-header-padding-top-wide) var(--emu-component-containers-isi-header-padding-right-wide) var(--emu-component-containers-isi-header-padding-bottom-wide) var(--emu-component-containers-isi-header-padding-left-wide);
  }
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__banner-content {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  cursor: pointer;
  padding-right: var(--emu-common-spacing-xs);
  flex: 1;
  display: block !important;
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__banner-content p {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  font-weight: var(--emu-common-font-weight-regular);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__banner-content p {
    font-size: 21px;
  }
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  align-self: stretch;
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper > div {
  height: 100%;
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button {
  padding-top: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-medium);
  flex-direction: row-reverse;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon {
  margin-right: var(--emu-common-spacing-none);
  width: 15px;
  height: 15px;
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon:before, [data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .aaaem-button__icon:after {
  background-color: var(--emu-semantic-colors-primary-dark);
  border-radius: var(--emu-common-border-radius-xs);
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button .cmp-button__text {
  color: var(--emu-semantic-colors-primary-dark);
  text-align: left;
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-right: 10px;
  margin-bottom: var(--emu-common-spacing-none);
  white-space: nowrap;
  min-width: 88px;
  line-height: 22px;
  display: block;
}

[data-component="container-isi-v2"] .gen-isi__container .aaaem-isi-banner .aaaem-isi-container__button-wrapper .cmp-button:active {
  outline: none;
}

[data-component="container-isi-v2"] .gen-isi__content {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-xl);
}

[data-component="container-isi-v2"] .gen-isi__content p a {
  word-break: break-all;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__content p i {
    display: inline-block;
    transform: skewX(8deg);
  }
}

[data-component="container-isi-v2"] .gen-isi__content--botox-cosmetic {
  margin-bottom: var(--emu-common-spacing-large);
}

[data-component="container-isi-v2"] .gen-isi__content--juvederm p .emphasis {
  margin-top: var(--emu-common-spacing-large);
  display: block;
}

[data-component="container-isi-v2"] .gen-isi__content--strattice {
  margin-bottom: var(--emu-common-spacing-none);
}

[data-component="container-isi-v2"] .gen-isi__heading .cmp-title__text {
  color: var(--emu-common-colors-dark-brown-500);
}

@media (max-width: 359px) {
  [data-component="container-isi-v2"] .gen-isi__heading .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    word-break: break-word;
    letter-spacing: normal;
  }
}

[data-component="container-isi-v2"] .gen-isi__heading i {
  padding-right: 3px;
  display: inline-block;
  transform: skewX(8deg);
}

[data-component="container-isi-v2"] .gen-isi__title {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: 10px;
}

[data-component="container-isi-v2"] .gen-isi__title .emu-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-bottom: var(--emu-common-spacing-none);
  line-height: 20px;
}

[data-component="container-isi-v2"] .gen-isi__info p, [data-component="container-isi-v2"] .gen-isi__info li {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-top: var(--emu-common-spacing-none);
  line-height: 20px;
}

[data-component="container-isi-v2"] .gen-isi__info p {
  font-weight: var(--emu-common-font-weight-regular);
}

[data-component="container-isi-v2"] .gen-isi__info p + p {
  margin-top: 12px;
}

[data-component="container-isi-v2"] .gen-isi__info p i {
  padding-right: 1.5px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__info p i {
    letter-spacing: -.25px;
  }
}

[data-component="container-isi-v2"] .gen-isi__info ul {
  margin-top: 13px;
  margin-bottom: 12px;
  padding-left: 24px;
  list-style: none;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__info ul {
    margin-top: 12px;
  }
}

[data-component="container-isi-v2"] .gen-isi__info li {
  position: relative;
}

[data-component="container-isi-v2"] .gen-isi__info li:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 7px;
  left: -14px;
}

[data-component="container-isi-v2"] .gen-isi__warnings-box {
  padding-top: var(--emu-common-spacing-large);
  padding-right: var(--emu-common-spacing-large);
  padding-bottom: 30px;
  padding-left: var(--emu-common-spacing-large);
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  margin-bottom: var(--emu-common-spacing-large);
  line-height: 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__warnings-box {
    padding-top: 29px;
    padding-bottom: var(--emu-common-spacing-large);
  }
}

[data-component="container-isi-v2"] .gen-isi__warnings-box p:not(:last-child) {
  margin-bottom: var(--emu-common-spacing-small);
}

[data-component="container-isi-v2"] .gen-isi__warnings-box ul {
  padding-left: 18px;
}

[data-component="container-isi-v2"] .gen-isi__warnings-box li {
  letter-spacing: .3px;
}

[data-component="container-isi-v2"] .gen-isi__warnings-box li:not(:last-child) {
  margin-bottom: 11px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__warnings-box li {
    margin-bottom: 11px;
  }
}

[data-component="container-isi-v2"] .gen-isi__warnings-box--natrelle {
  margin-top: var(--emu-common-spacing-large);
}

[data-component="container-isi-v2"] .gen-isi__warnings-box--natrelle ul {
  margin-top: 10px;
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: 22px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .gen-isi__warnings-box--natrelle ul {
    margin-top: 13px;
  }
}

[data-component="container-isi-v2"] .gen-isi__warnings-box--natrelle li {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  letter-spacing: 0;
}

[data-component="container-isi-v2"] .gen-isi__warnings-box--natrelle li:not(:last-child) {
  margin-bottom: var(--emu-common-spacing-none);
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

[data-component="title"][class*="u-typography--"] h1, [data-component="title"][class*="u-typography--"] h2, [data-component="title"][class*="u-typography--"] h3, [data-component="title"][class*="u-typography--"] h4, [data-component="title"][class*="u-typography--"] h5, [data-component="title"][class*="u-typography--"] h6, [data-component="title"][class*="u-typography--"] li, [data-component="title"][class*="u-typography--"] p, [data-component="title"][class*="u-typography--"] a, [data-component="title"][class*="u-typography--"] span, [data-component="text"][class*="u-typography--"] h1, [data-component="text"][class*="u-typography--"] h2, [data-component="text"][class*="u-typography--"] h3, [data-component="text"][class*="u-typography--"] h4, [data-component="text"][class*="u-typography--"] h5, [data-component="text"][class*="u-typography--"] h6, [data-component="text"][class*="u-typography--"] li, [data-component="text"][class*="u-typography--"] p, [data-component="text"][class*="u-typography--"] a, [data-component="text"][class*="u-typography--"] span {
  font: inherit;
}

.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
}

@media (min-width: 768px) {
  .u-typography--h1 {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);
}

@media (min-width: 768px) {
  .u-typography--h2 {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);
}

@media (min-width: 768px) {
  .u-typography--h3 {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);
}

@media (min-width: 768px) {
  .u-typography--h4 {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5 {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

@media (min-width: 768px) {
  .u-typography--h5 {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-sm {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xs {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xxs {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

[data-component="text"][class*="u-font-family--"] h1, [data-component="text"][class*="u-font-family--"] h2, [data-component="text"][class*="u-font-family--"] h3, [data-component="text"][class*="u-font-family--"] h4, [data-component="text"][class*="u-font-family--"] h5, [data-component="text"][class*="u-font-family--"] h6, [data-component="text"][class*="u-font-family--"] li, [data-component="text"][class*="u-font-family--"] p, [data-component="text"][class*="u-font-family--"] a, [data-component="text"][class*="u-font-family--"] span, [data-component="title"][class*="u-font-family--"] h1, [data-component="title"][class*="u-font-family--"] h2, [data-component="title"][class*="u-font-family--"] h3, [data-component="title"][class*="u-font-family--"] h4, [data-component="title"][class*="u-font-family--"] h5, [data-component="title"][class*="u-font-family--"] h6, [data-component="title"][class*="u-font-family--"] li, [data-component="title"][class*="u-font-family--"] p, [data-component="title"][class*="u-font-family--"] a, [data-component="title"][class*="u-font-family--"] span {
  font-family: inherit;
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

.u-container--max {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-fancy-border:before, .demo-section__main-title:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  width: 80px;
  padding-bottom: 10px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .u-fancy-border:before, .demo-section__main-title:before {
    width: 180px;
    padding-bottom: 25px;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__description {
    padding-top: 9px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__action-container .emu-button-v2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__image img {
    object-fit: contain;
    object-position: calc(50% + 3px);
    max-height: 220px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .content-container {
    padding-bottom: 17px;
    position: static;
  }
}

.u-teaser-disclaimer-text-color--dark .content-container .emu-teaser__disclaimer p {
  color: var(--emu-semantic-colors-secondary-dark);
}

.u-form-2-col {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .u-form-2-col {
    flex-direction: row;
    gap: 17px;
  }
}

.u-form-2-col .text {
  flex: 1;
}

@media (max-width: 767px) {
  .u-form-el-stacked-in-mobile {
    flex-direction: column;
    display: flex;
  }

  .u-form-el-stacked-in-mobile.emu-form-radio[data-layout="inline"], .u-form-el-stacked-in-mobile.emu-form-checkbox[data-layout="inline"] {
    gap: var(--emu-common-spacing-none);
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo svg {
  display: block;
}

.demo-code, .demo-section__txt--list-as-code li, .demo-section__txt u {
  background-color: var(--emu-common-colors-black);
  border-radius: var(--emu-common-border-radius-xs);
  padding: var(--emu-common-spacing-xxs) var(--emu-common-spacing-xs);
  font-size: 14px;
  color: orange !important;
}

.demo-section__main-title {
  width: 100%;
  overflow: hidden;
}

.demo-section__item {
  border-bottom: 1px solid #a1a1a1;
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.demo-section__txt h2, .demo-section__txt h4 {
  letter-spacing: .5px;
  line-height: 150%;
  display: inline-flex;
}

.demo-section__txt h2 {
  font: var(--emu-semantic-typography-wide-headings-medium);
  border-bottom: var(--emu-common-border-width-thick) solid var(--emu-semantic-colors-primary-dark);
}

.demo-section__txt h4 {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--emu-semantic-colors-primary-dark);
  text-decoration-color: var(--emu-semantic-colors-primary-dark);
  font-size: 18px;
  text-decoration-thickness: 2px;
}

.demo-section__txt ul {
  padding-left: 20px;
}

.demo-section__txt p, .demo-section__txt li {
  color: #444;
  font-size: 16px;
}

.demo-section__txt u {
  text-decoration: none;
}

.demo-section__txt--list-as-code ul {
  padding-left: var(--emu-common-spacing-none);
  flex-flow: wrap;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.demo-section__txt--list-as-code li {
  margin-top: var(--emu-common-spacing-none);
  list-style: none;
}

.cq-Editable-dom--container .demo-section__preview {
  outline: 2px dashed var(--emu-semantic-colors-primary-dark);
  position: relative;
  overflow: hidden;
}

.cq-Editable-dom--container .demo-section__preview:after {
  content: "COPY THE CONTENTS INSIDE THIS DOTTED CONTAINER";
  background-color: var(--emu-semantic-colors-primary-dark);
  text-align: center;
  color: var(--emu-common-colors-white);
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cq-Editable-dom--container .demo-section__preview > div:first-child {
  margin-top: 55px;
}

[data-component="embed"] .plyr {
  justify-content: center;
  align-items: center;
  display: flex;
}

[data-component="embed"] .plyr__control {
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
}

[data-component="embed"] .plyr__control--overlaid {
  background-image: url("resources/images/play_button-mobile.svg");
  background-color: var(--emu-common-colors-transparent);
  width: 50px;
  height: 50px;
  inset: auto -5px -4px auto;
}

@media (min-width: 1024px) {
  [data-component="embed"] .plyr__control--overlaid {
    background-image: url("resources/images/play_button.svg");
    width: 80px;
    height: 80px;
    bottom: -8px;
    right: -8px;
  }
}

[data-component="embed"] .plyr__control--overlaid:hover {
  background-image: url("resources/images/play_button-mobile.svg");
  background-color: var(--emu-common-colors-transparent);
}

@media (min-width: 1024px) {
  [data-component="embed"] .plyr__control--overlaid:hover {
    background-image: url("resources/images/play_button.svg");
  }
}

[data-component="embed"] .plyr__control--overlaid svg {
  display: none;
}

[data-component="embed"] .plyr--full-ui .plyr__video-embed .plyr__video-embed__container {
  transform: translateY(0);
}

[data-component="embed"] .plyr--stopped .plyr__controls, [data-component="embed"] .plyr--paused .plyr__controls {
  visibility: hidden;
}

.flippable-cards-wrapper {
  justify-content: center;
  gap: var(--emu-common-spacing-medium);
  flex-flow: wrap;
  display: flex;
}

@media (min-width: 1024px) {
  .flippable-cards-wrapper {
    justify-content: left;
  }
}

.flippable-card {
  width: 339px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1440px) {
  .flippable-card {
    width: 340px;
  }
}

@media (pointer: fine) {
  .flippable-card:hover .flippable-card__face--back {
    opacity: 1;
    pointer-events: initial;
  }

  .flippable-card:hover .flippable-card__face--front {
    opacity: 0;
    pointer-events: none;
  }
}

@media (pointer: coarse) {
  .flippable-card.flippable-card--click-active .flippable-card__face--back {
    opacity: 1;
    pointer-events: initial;
  }

  .flippable-card.flippable-card--click-active .flippable-card__face--front {
    opacity: 0;
    pointer-events: none;
  }
}

.flippable-card__face {
  transition: all var(--emu-common-other-time-duration-instant) linear;
}

.flippable-card__face--back {
  opacity: 0;
  pointer-events: none;
}

.flippable-card__face--abs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.flippable-card__face img {
  width: 100%;
}

.cq-Editable-dom--container .flippable-card__face {
  opacity: 1;
  position: static;
}

.flippable-card__content {
  padding-left: var(--emu-common-spacing-large);
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 29px;
  padding-bottom: 29px;
  padding-right: 22px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.cq-Editable-dom--container .flippable-card__content {
  opacity: 1;
  position: static;
}

.flippable-card__content > div:last-child {
  margin-top: auto;
}

.flippable-card__content > div:nth-child(2) {
  scrollbar-color: var(--emu-semantic-colors-primary-500) #0006;
  scrollbar-width: thin;
  flex: 1;
  margin-bottom: 10px;
  padding-right: 10px;
  overflow: auto;
}

.flippable-card__survey-data {
  margin-top: 10px;
}

.flippable-card__logo {
  min-height: 60px;
  margin-bottom: 37px;
}

.cq-Editable-dom--container .flippable-card__logo {
  opacity: 1;
  position: static;
}

.flippable-card__logo img {
  width: auto;
}

.flippable-card__link {
  justify-content: flex-end;
  line-height: 20px;
  display: flex;
}

.flippable-card__link a {
  text-underline-offset: 3px;
  align-items: center;
  display: flex;
}

.flippable-card__link a img {
  margin-left: var(--emu-common-spacing-small);
}

.flippable-card__footnotes {
  margin-right: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-large);
  margin-left: var(--emu-common-spacing-large);
  position: absolute;
  bottom: 0;
  left: 0;
}

.cq-Editable-dom--container .flippable-card__footnotes {
  color: var(--emu-common-colors-black) !important;
  position: static !important;
}

.gen-footer__inner {
  padding-top: var(--emu-common-spacing-large);
  padding-right: 37px;
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: 37px;
}

@media (min-width: 1024px) {
  .gen-footer__inner {
    padding-top: 34px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 34px;
    padding-left: var(--emu-common-spacing-medium);
  }

  .gen-footer__inner > .container {
    max-width: var(--emu-common-sizing-container) !important;
    float: unset !important;
    clear: both !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .gen-footer__top-section {
    display: flex;
  }

  .gen-footer__top-section .image {
    margin-right: auto;
  }
}

.gen-footer__links {
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: 34px;
}

@media (min-width: 1024px) {
  .gen-footer__links {
    margin-top: var(--emu-common-spacing-none);
  }
}

.gen-footer__links ul {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  .gen-footer__links ul {
    flex-direction: row;
  }
}

.gen-footer__links li {
  margin-top: var(--emu-common-spacing-none);
}

.gen-footer__links li:not(:last-child) {
  margin-bottom: 11.5px;
}

@media (min-width: 1024px) {
  .gen-footer__links li:not(:last-child) {
    margin-right: var(--emu-common-spacing-large);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  .gen-footer__links li:not(:last-child) {
    margin-right: var(--emu-common-spacing-xl);
  }
}

.gen-footer__links a {
  line-height: 19.2px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .gen-footer__bottom-section.aaaem-container {
    margin-top: 33px;
  }

  .gen-footer__copyright p:not(:first-child) {
    margin-top: 11px;
  }
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

[data-component="title"][class*="u-typography--"] h1, [data-component="title"][class*="u-typography--"] h2, [data-component="title"][class*="u-typography--"] h3, [data-component="title"][class*="u-typography--"] h4, [data-component="title"][class*="u-typography--"] h5, [data-component="title"][class*="u-typography--"] h6, [data-component="title"][class*="u-typography--"] li, [data-component="title"][class*="u-typography--"] p, [data-component="title"][class*="u-typography--"] a, [data-component="title"][class*="u-typography--"] span, [data-component="text"][class*="u-typography--"] h1, [data-component="text"][class*="u-typography--"] h2, [data-component="text"][class*="u-typography--"] h3, [data-component="text"][class*="u-typography--"] h4, [data-component="text"][class*="u-typography--"] h5, [data-component="text"][class*="u-typography--"] h6, [data-component="text"][class*="u-typography--"] li, [data-component="text"][class*="u-typography--"] p, [data-component="text"][class*="u-typography--"] a, [data-component="text"][class*="u-typography--"] span {
  font: inherit;
}

.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
}

@media (min-width: 768px) {
  .u-typography--h1 {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);
}

@media (min-width: 768px) {
  .u-typography--h2 {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);
}

@media (min-width: 768px) {
  .u-typography--h3 {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);
}

@media (min-width: 768px) {
  .u-typography--h4 {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5, .emu-form__message .emphasis {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

@media (min-width: 768px) {
  .u-typography--h5, .emu-form__message .emphasis {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm, .emu-form-checkbox__info-text, .emu-form-radio__info-text, .emu-form-dropdown__info-text {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-sm, .emu-form-checkbox__info-text, .emu-form-radio__info-text, .emu-form-dropdown__info-text {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xs {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs, .emu-form-checkbox__error-text, .emu-form-radio__error-text, .emu-form-dropdown__error-text, .emu-form-recaptcha__error-text, .emu-form-textarea__error-text, .emu-form-text__info-text, .emu-form-text__error-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xxs, .emu-form-checkbox__error-text, .emu-form-radio__error-text, .emu-form-dropdown__error-text, .emu-form-recaptcha__error-text, .emu-form-textarea__error-text, .emu-form-text__info-text, .emu-form-text__error-text {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

[data-component="text"][class*="u-font-family--"] h1, [data-component="text"][class*="u-font-family--"] h2, [data-component="text"][class*="u-font-family--"] h3, [data-component="text"][class*="u-font-family--"] h4, [data-component="text"][class*="u-font-family--"] h5, [data-component="text"][class*="u-font-family--"] h6, [data-component="text"][class*="u-font-family--"] li, [data-component="text"][class*="u-font-family--"] p, [data-component="text"][class*="u-font-family--"] a, [data-component="text"][class*="u-font-family--"] span, [data-component="title"][class*="u-font-family--"] h1, [data-component="title"][class*="u-font-family--"] h2, [data-component="title"][class*="u-font-family--"] h3, [data-component="title"][class*="u-font-family--"] h4, [data-component="title"][class*="u-font-family--"] h5, [data-component="title"][class*="u-font-family--"] h6, [data-component="title"][class*="u-font-family--"] li, [data-component="title"][class*="u-font-family--"] p, [data-component="title"][class*="u-font-family--"] a, [data-component="title"][class*="u-font-family--"] span {
  font-family: inherit;
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg, .emu-form__message .emphasis {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

.u-container--max {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-fancy-border:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  width: 80px;
  padding-bottom: 10px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .u-fancy-border:before {
    width: 180px;
    padding-bottom: 25px;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__description {
    padding-top: 9px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__action-container .emu-button-v2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__image img {
    object-fit: contain;
    object-position: calc(50% + 3px);
    max-height: 220px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .content-container {
    padding-bottom: 17px;
    position: static;
  }
}

.u-teaser-disclaimer-text-color--dark .content-container .emu-teaser__disclaimer p {
  color: var(--emu-semantic-colors-secondary-dark);
}

.u-form-2-col {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .u-form-2-col {
    flex-direction: row;
    gap: 17px;
  }
}

.u-form-2-col .text {
  flex: 1;
}

@media (max-width: 767px) {
  .u-form-el-stacked-in-mobile {
    flex-direction: column;
    display: flex;
  }

  .u-form-el-stacked-in-mobile.emu-form-radio[data-layout="inline"], .u-form-el-stacked-in-mobile.emu-form-checkbox[data-layout="inline"] {
    gap: var(--emu-common-spacing-none);
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo svg {
  display: block;
}

.emu-form {
  transition: padding .2s ease-out;
  position: relative;
}

.emu-form--loading {
  padding-bottom: 140px;
  transition: padding .2s ease-in;
}

.emu-form--loading .emu-form__loader {
  opacity: 1;
}

.emu-form__loader {
  opacity: 0;
  transition: opacity .2s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
}

.emu-form__loader svg {
  animation-duration: 1s;
}

.emu-form-text {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
  width: 100%;
}

.emu-form-text__input {
  width: 100%;
  height: 60px;
}

.emu-form-text__input:disabled::placeholder {
  background-color: var(--emu-common-colors-light-grey-500);
}

.emu-form-text__input::-webkit-inner-spin-button {
  appearance: none;
  display: none;
}

.emu-form-textarea {
  margin-top: var(--emu-common-spacing-none);
  width: 100%;
  margin-bottom: 18px;
}

.emu-form-textarea__container, .emu-form-textarea__textarea {
  width: 100%;
}

.emu-form-textarea__textarea {
  resize: none;
}

.emu-form-textarea__label {
  margin-bottom: 5px;
  line-height: 25px;
}

.emu-form-recaptcha {
  margin-top: var(--emu-common-spacing-medium);
  margin-bottom: 22px;
}

@media (min-width: 1024px) {
  .emu-form-recaptcha {
    margin-bottom: 34px;
  }
}

.emu-form .emu-form-dropdown .emu-dropdown-menu__group {
  max-height: 232px;
  overflow-y: auto;
}

.emu-form .emu-form-dropdown:not(.js-hide-dropdown):not([data-multiple]) .emu-dropdown-menu__group {
  border-top-width: var(--emu-common-border-width-thin);
}

.emu-form-dropdown {
  margin-top: var(--emu-common-spacing-none);
  position: relative;
}

.emu-form-dropdown .emu-dropdown-menu__button {
  width: 100% !important;
}

.emu-form-dropdown .emu-dropdown-menu__button svg {
  flex-shrink: 0;
  width: 25px;
  height: 20px;
}

.emu-form-dropdown .emu-dropdown-menu__button:disabled {
  background-color: var(--emu-common-colors-light-grey-500);
  color: var(--emu-common-colors-medium-grey-500);
  border-color: var(--emu-common-colors-medium-grey-500);
}

.emu-form-dropdown .emu-dropdown-menu__button:disabled svg {
  fill: var(--emu-common-colors-medium-grey-500);
}

.emu-form-dropdown .emu-dropdown-menu__button span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emu-form-dropdown .emu-dropdown-menu__group {
  width: 100% !important;
}

@media (min-width: 768px) {
  .emu-form-dropdown .emu-dropdown-menu__group {
    min-width: 240px !important;
  }
}

.emu-form-dropdown .emu-dropdown-menu__group li[data-value="--"] {
  display: none;
}

.emu-form-dropdown .emu-dropdown-menu__group li:first-child {
  border-top-width: var(--emu-common-border-width-none);
}

.emu-form-dropdown .emu-dropdown-menu__group li + li {
  margin-top: var(--emu-common-spacing-none);
}

.emu-form-radio {
  display: flex;
}

.emu-form-radio[data-layout="inline"] {
  column-gap: var(--emu-common-spacing-xl);
  flex-wrap: wrap;
}

.emu-form-radio[data-layout="stacked"] {
  gap: var(--emu-common-spacing-medium);
  flex-direction: column;
}

.emu-form-radio__error-text {
  flex-basis: 100%;
}

.emu-form-checkbox {
  display: flex;
}

.emu-form-checkbox[data-layout="inline"] {
  column-gap: var(--emu-common-spacing-xl);
  flex-wrap: wrap;
}

.emu-form-checkbox[data-layout="stacked"] {
  gap: var(--emu-common-spacing-medium);
  flex-direction: column;
}

.emu-form-checkbox__label span {
  flex: 1;
}

.emu-form-checkbox__error-text {
  flex-basis: 100%;
}

.emu-form__message {
  border: var(--emu-common-border-width-thin) solid;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-large);
}

@media (min-width: 768px) {
  .emu-form__message {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.emu-form__message p + p {
  margin-top: var(--emu-common-spacing-medium);
  letter-spacing: -.16px;
}

@media (min-width: 1024px) {
  .emu-form__message p + p {
    letter-spacing: normal;
  }
}

.emu-form__message .emphasis {
  margin-bottom: var(--emu-common-spacing-medium);
}

.emu-form__message--success {
  border-color: var(--emu-semantic-colors-green-50);
  background: #4caf500d;
}

.emu-form__message--error {
  border-color: var(--emu-semantic-colors-error-50);
  background: #eb00000d;
}

.emu-form__message--api-response {
  margin-top: var(--emu-common-spacing-large);
}

.emu-form .js-has-error .emu-form-text__input, .emu-form .js-has-error .emu-form-textarea__textarea, .emu-form .js-has-error .emu-dropdown-menu__button {
  background-color: var(--emu-component-ingredients-text-input-color-background-error-light);
  border-color: var(--emu-component-ingredients-text-input-color-border-error-light);
  color: var(--emu-component-ingredients-text-input-label-text-color-error-light);
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

[data-component="title"][class*="u-typography--"] h1, [data-component="title"][class*="u-typography--"] h2, [data-component="title"][class*="u-typography--"] h3, [data-component="title"][class*="u-typography--"] h4, [data-component="title"][class*="u-typography--"] h5, [data-component="title"][class*="u-typography--"] h6, [data-component="title"][class*="u-typography--"] li, [data-component="title"][class*="u-typography--"] p, [data-component="title"][class*="u-typography--"] a, [data-component="title"][class*="u-typography--"] span, [data-component="text"][class*="u-typography--"] h1, [data-component="text"][class*="u-typography--"] h2, [data-component="text"][class*="u-typography--"] h3, [data-component="text"][class*="u-typography--"] h4, [data-component="text"][class*="u-typography--"] h5, [data-component="text"][class*="u-typography--"] h6, [data-component="text"][class*="u-typography--"] li, [data-component="text"][class*="u-typography--"] p, [data-component="text"][class*="u-typography--"] a, [data-component="text"][class*="u-typography--"] span {
  font: inherit;
}

.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
}

@media (min-width: 768px) {
  .u-typography--h1 {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);
}

@media (min-width: 768px) {
  .u-typography--h2 {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);
}

@media (min-width: 768px) {
  .u-typography--h3 {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);
}

@media (min-width: 768px) {
  .u-typography--h4 {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5 {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

@media (min-width: 768px) {
  .u-typography--h5 {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-sm {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xs {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xxs {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

[data-component="text"][class*="u-font-family--"] h1, [data-component="text"][class*="u-font-family--"] h2, [data-component="text"][class*="u-font-family--"] h3, [data-component="text"][class*="u-font-family--"] h4, [data-component="text"][class*="u-font-family--"] h5, [data-component="text"][class*="u-font-family--"] h6, [data-component="text"][class*="u-font-family--"] li, [data-component="text"][class*="u-font-family--"] p, [data-component="text"][class*="u-font-family--"] a, [data-component="text"][class*="u-font-family--"] span, [data-component="title"][class*="u-font-family--"] h1, [data-component="title"][class*="u-font-family--"] h2, [data-component="title"][class*="u-font-family--"] h3, [data-component="title"][class*="u-font-family--"] h4, [data-component="title"][class*="u-font-family--"] h5, [data-component="title"][class*="u-font-family--"] h6, [data-component="title"][class*="u-font-family--"] li, [data-component="title"][class*="u-font-family--"] p, [data-component="title"][class*="u-font-family--"] a, [data-component="title"][class*="u-font-family--"] span {
  font-family: inherit;
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

.u-container--max {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-fancy-border:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  width: 80px;
  padding-bottom: 10px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .u-fancy-border:before {
    width: 180px;
    padding-bottom: 25px;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__description {
    padding-top: 9px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__action-container .emu-button-v2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__image img {
    object-fit: contain;
    object-position: calc(50% + 3px);
    max-height: 220px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .content-container {
    padding-bottom: 17px;
    position: static;
  }
}

.u-teaser-disclaimer-text-color--dark .content-container .emu-teaser__disclaimer p {
  color: var(--emu-semantic-colors-secondary-dark);
}

.u-form-2-col {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .u-form-2-col {
    flex-direction: row;
    gap: 17px;
  }
}

.u-form-2-col .text {
  flex: 1;
}

@media (max-width: 767px) {
  .u-form-el-stacked-in-mobile {
    flex-direction: column;
    display: flex;
  }

  .u-form-el-stacked-in-mobile.emu-form-radio[data-layout="inline"], .u-form-el-stacked-in-mobile.emu-form-checkbox[data-layout="inline"] {
    gap: var(--emu-common-spacing-none);
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo svg {
  display: block;
}

.gen-form a {
  color: var(--emu-semantic-colors-primary-dark);
}

.gen-form__inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: var(--emu-common-spacing-small);
  flex-direction: column;
  gap: 57px;
  display: flex;
}

@media (min-width: 1024px) {
  .gen-form__inner {
    padding-bottom: 61px;
    padding-right: 17px;
    padding-left: var(--emu-common-spacing-medium);
    flex-direction: row;
  }

  .gen-form__inner > .container:first-child {
    flex-grow: 1;
    flex-basis: 655px;
  }

  .gen-form__inner > .container:last-child {
    flex-grow: 1;
    flex-basis: 695px;
  }
}

.gen-form__section {
  padding-bottom: var(--emu-common-spacing-none);
}

.gen-form__section--form {
  padding-top: 67px;
  padding-right: 29px;
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: 29px;
}

@media (min-width: 768px) {
  .gen-form__section--form {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-large);
  }
}

.gen-form__pretitle {
  margin-bottom: 7px;
}

@media (min-width: 1024px) {
  .gen-form__pretitle {
    margin-top: var(--emu-common-spacing-xxs);
  }
}

.gen-form__title {
  margin-bottom: var(--emu-common-spacing-large);
  max-width: 452px;
}

.gen-form__content-title {
  margin-bottom: 15px;
}

@media (min-width: 1024px) {
  .gen-form__content-title {
    margin-bottom: 17px;
  }
}

@media (min-width: 768px) {
  .gen-form__form-intro {
    max-width: 460px;
  }
}

.gen-form__form-preheading {
  margin-bottom: 10px;
}

.gen-form__form-preheading .emu-title__text {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-wide-medium);
}

.gen-form__form-heading {
  margin-bottom: var(--emu-common-spacing-large);
}

.gen-form__form-heading .emu-title__text {
  font-size: var(--emu-semantic-font-sizes-wide-large);
  line-height: var(--emu-common-line-heights-narrow-xl);
}

.gen-form__form-desc {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .gen-form__form-desc {
    margin-bottom: 33px;
  }
}

.gen-form__form-submit {
  justify-content: center;
  width: 100%;
}

.gen-form__form-submit.emu-form-button {
  padding-top: 15px;
  padding-bottom: var(--emu-common-spacing-medium);
}

.gen-form__helper-text {
  margin-top: -6px;
  margin-bottom: 18px;
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  header.experiencefragment {
    position: fixed;
  }
}

.gen-header {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-medium-brown-500);
  background-color: var(--emu-common-colors-dark-brown-500);
  border-bottom: none;
  width: 100%;
  transition: background .5s;
}

@media (min-width: 1024px) {
  .gen-header {
    background: linear-gradient(0deg, #49272800 0%, var(--emu-common-colors-dark-brown-500) 100%);
  }
}

.gen-header__inner {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: var(--emu-common-spacing-small);
  padding-right: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .gen-header__inner {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
  }
}

@media (max-width: 1023px) {
  .gen-header__inner {
    position: relative;
  }

  .gen-header__inner:after {
    content: "";
    border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-medium-brown-500);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 1024px) {
  .gen-header__inner > .container {
    max-width: var(--emu-common-sizing-container) !important;
    float: unset !important;
    clear: both !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .gen-header__main {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .gen-header__main > .container:first-child {
    margin-right: var(--emu-common-spacing-medium);
    flex-shrink: 0;
  }

  .gen-header__main > .container:last-child {
    width: 100%;
    max-width: 768px;
  }
}

.gen-header__topbar {
  flex: 1 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.gen-header__toggler.emu-button-v2.emu-button:not(a) {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .gen-header__toggler.emu-button-v2.emu-button:not(a) {
    display: none;
  }
}

.gen-header__toggler.emu-button-v2.emu-button:not(a), .gen-header__toggler.emu-button-v2.emu-button:not(a):hover, .gen-header__toggler.emu-button-v2.emu-button:not(a):active, .gen-header__toggler.emu-button-v2.emu-button:not(a):focus {
  background-color: var(--emu-common-colors-dark-brown-500);
  border-color: var(--emu-common-colors-dark-brown-500);
}

.gen-header__toggler span {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

.gen-header__nav-container {
  display: none;
}

.gen-header__nav-container.js-toggle-on {
  display: block;
}

@media (min-width: 1024px) {
  .gen-header__nav-container {
    display: block;
  }

  .gen-header--background {
    background-color: var(--emu-common-colors-dark-brown-500);
    border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-medium-brown-500);
  }
}

.gen-header .emu-navigation__content-wrapper {
  top: 70px;
}

@media (min-width: 768px) {
  .gen-header .emu-navigation__content-wrapper {
    position: absolute;
  }
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper {
    position: static;
  }
}

.gen-header .emu-navigation__content-wrapper nav {
  background-color: var(--emu-common-colors-white);
  height: calc(100vh - 71px);
  overflow: auto;
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav {
    background-color: var(--emu-common-colors-transparent);
    overflow: initial;
    height: auto;
  }
}

.gen-header .emu-navigation__content-wrapper nav a {
  padding: var(--emu-common-spacing-none);
  display: contents;
}

@media (min-width: 768px) {
  .gen-header .emu-navigation__content-wrapper nav > ul {
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul {
    flex-direction: row;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-medium-brown-500);
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item {
    border-bottom: none;
    position: relative;
  }

  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:not(:last-child) {
    margin-right: auto;
  }

  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:hover > span:after {
    content: "";
    background-image: url("resources/images/caret-down-mobile.svg");
  }

  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:hover.js-open--mobile ul {
    display: block;
  }

  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:after {
    content: "";
    width: 100%;
    height: 58px;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu.js-open--mobile > span:after, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu > span:after {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu.js-open--mobile > span:after, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu > span:after {
    transform: none;
  }
}

@media (max-width: 1023px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu.js-open--mobile ul, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu ul {
    display: block;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.js-open--mobile ul {
  display: none;
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a {
  color: var(--emu-common-colors-dark-brown-500);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: 23.4px;
  font-family: var(--emu-semantic-font-families-beatrice-regular);
  border: none;
  align-items: center;
  padding: 18px 37px;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a {
    color: var(--emu-common-colors-white);
    line-height: var(--emu-semantic-sizing-one-line-height-narrow);
    padding: var(--emu-common-spacing-none);
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span:after, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a:after {
  content: "";
  transition: all var(--emu-common-other-time-transition-short) linear;
  background-image: url("resources/images/caret-down-mobile.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: inline;
  position: absolute;
  right: 37px;
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span:after, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a:after {
    content: "";
    background-image: none;
    background-size: var(--emu-common-sizing-small);
    height: var(--emu-common-sizing-small);
    width: var(--emu-common-sizing-small);
    margin-left: auto;
    margin-right: auto;
    bottom: -32px;
    left: 4px;
    right: 0;
    transform: none;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span:hover, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a:hover {
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span:hover, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a:hover {
    background-color: var(--emu-common-colors-transparent);
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > span:last-child:after, .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a:last-child:after {
  content: none;
}

@media (max-width: 1023px) {
  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:not(.emu-navigation__item-parent) > span {
    padding: var(--emu-common-spacing-none);
  }

  .gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:not(.emu-navigation__item-parent) > span a {
    width: 100%;
    padding: 18px 37px;
    display: block;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item > a > span {
  padding: var(--emu-common-spacing-none);
}

.gen-header .emu-navigation__content-wrapper nav li + li {
  margin-top: var(--emu-common-spacing-none);
}

.gen-header .emu-navigation__content-wrapper nav > ul ul {
  border: none;
}

@media (min-width: 768px) {
  .gen-header .emu-navigation__content-wrapper nav > ul ul {
    position: relative;
  }
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul ul {
    padding-top: 13px;
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-none);
    border-top: var(--emu-common-border-width-thick) solid var(--emu-common-colors-dark-brown-500);
    width: 272px;
    position: absolute;
    top: 62px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 3px 8px #0000003d;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul ul > li {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-white);
  background-color: var(--emu-common-colors-light-grey-500);
}

.gen-header .emu-navigation__content-wrapper nav > ul ul > li > a {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
  align-items: flex-start;
  gap: var(--emu-common-spacing-small);
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  flex-direction: column;
  padding: 18px 37px 17px;
  display: flex;
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul ul > li > a {
    background-color: var(--emu-common-colors-white);
    line-height: var(--emu-semantic-line-heights-narrow-xs);
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul ul > li > a:hover {
  background-color: var(--emu-common-colors-light-grey-500);
}

.gen-header .emu-navigation__content-wrapper nav > ul ul > li > a > span {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul ul > li > a > span {
    line-height: 20px;
  }
}

.gen-header .emu-navigation__content-wrapper nav > ul ul > li > a b {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-common-font-sizes-wide-medium);
  font-weight: var(--emu-common-font-weight-light);
  margin-bottom: 9px;
  line-height: 20.8px;
  display: block;
}

@media (min-width: 1024px) {
  .gen-header .emu-navigation__content-wrapper nav > ul ul > li > a b {
    margin-bottom: 7px;
    line-height: 12px;
  }

  .gen-header--background .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:hover > span:after, .gen-header--background .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu > span:after {
    content: "";
    background-image: url("resources/images/caret-down-desktop.svg");
    transform: none;
  }
}

.gen-header--background .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item:hover > span:last-child:after, .gen-header--background .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item.show-submenu > span:last-child:after {
  content: none;
}

.gen-header--background .emu-navigation__content-wrapper nav > ul ul {
  top: 63px;
}

.intro-group__inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-right: 37px;
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: 37px;
}

@media (min-width: 1024px) {
  .intro-group__inner {
    padding-left: 132px;
    padding-right: 132px;
  }
}

.intro-group__item-spacing-block {
  padding-bottom: var(--emu-common-spacing-xl);
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-500);
  margin-bottom: var(--emu-common-spacing-xl);
}

.intro-group .page-intro__wrapper {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .intro-group .page-intro__content-wrapper {
    max-width: 816px;
  }
}

.intro-group .page-intro__title {
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  .intro-group .page-intro__text {
    max-width: 812px;
  }
}

.intro-group .page-intro__text p {
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .intro-group .page-intro__text p {
    line-height: 25px;
  }
}

.intro-group .page-intro__text p a {
  color: var(--emu-common-colors-dark-brown-500);
}

.page-intro__wrapper {
  padding-top: var(--emu-common-spacing-large);
  padding-right: 46px;
  padding-bottom: var(--emu-common-spacing-xl);
  padding-left: 37px;
}

@media (min-width: 1024px) {
  .page-intro__wrapper {
    justify-content: space-between;
    padding-top: 66px;
    padding-left: 135px;
    padding-right: 137px;
    display: flex;
  }

  .page-intro__wrapper > .container:first-child {
    margin-right: var(--emu-common-spacing-medium);
  }

  .page-intro__title-wrapper {
    width: 100%;
    max-width: 340px;
  }
}

.page-intro__title .cmp-title__text {
  margin-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  .page-intro__title .cmp-title__text {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

.page-intro__subtitle .cmp-title__text {
  margin-bottom: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .page-intro__content-wrapper {
    max-width: 812px;
  }
}

.page-intro__text p {
  font-weight: inherit;
  padding-right: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  .page-intro__text p {
    padding-right: var(--emu-common-spacing-none);
  }
}

.page-intro__text.cmp-text .emphasis {
  margin-bottom: var(--emu-common-spacing-medium);
  display: block;
}

.page-intro__text i {
  letter-spacing: -.32px;
}

.page-intro__text ul {
  margin-bottom: 13px;
  margin-top: var(--emu-common-spacing-none);
  padding-left: 23px;
  list-style: none;
}

@media (min-width: 1024px) {
  .page-intro__text ul {
    margin-bottom: 14px;
  }
}

.page-intro__text li {
  margin-top: var(--emu-common-spacing-none);
  position: relative;
}

.page-intro__text li:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: -10px;
}

.page-intro__content-block > .button .emu-button-v2.emu-button-v2 {
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .page-intro__content-block > .button .emu-button-v2.emu-button-v2 {
    padding-left: 13px;
  }
}

.page-intro__content-block-spacing-block {
  padding-bottom: var(--emu-common-spacing-large);
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-500);
  margin-bottom: var(--emu-common-spacing-large);
}

.page-intro__content-block-title, .page-intro__content-block-subtitle {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .page-intro__content-block-subtitle {
    margin-top: var(--emu-common-spacing-small);
  }
}

.page-intro__cta {
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

@media (min-width: 1024px) {
  .page-intro__cta {
    max-width: 340px;
    margin-top: 31px;
  }

  .page-intro--with-logo .page-intro__wrapper {
    padding-right: 160px;
  }
}

@media (max-width: 1023px) {
  .page-intro--with-logo .page-intro__img {
    margin-top: var(--emu-common-spacing-large);
  }
}

.page-intro--with-logo .page-intro__img svg {
  display: block;
}

.page-intro__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
  margin-top: var(--emu-common-spacing-large);
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  padding-left: 47px;
  padding-right: 47px;
}

.page-intro__cta-container {
  padding-left: 37px;
  padding-bottom: var(--emu-common-spacing-xl);
}

@media (min-width: 1024px) {
  .page-intro__cta-container {
    padding-left: 135px;
  }
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view, .tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

[class*="u-text-color"] h1, [class*="u-text-color"] h2, [class*="u-text-color"] h3, [class*="u-text-color"] h4, [class*="u-text-color"] h5, [class*="u-text-color"] h6, [class*="u-text-color"] li, [class*="u-text-color"] p, [class*="u-text-color"] a, [class*="u-text-color"] span {
  color: inherit;
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

[data-component="title"][class*="u-typography--"] h1, [data-component="title"][class*="u-typography--"] h2, [data-component="title"][class*="u-typography--"] h3, [data-component="title"][class*="u-typography--"] h4, [data-component="title"][class*="u-typography--"] h5, [data-component="title"][class*="u-typography--"] h6, [data-component="title"][class*="u-typography--"] li, [data-component="title"][class*="u-typography--"] p, [data-component="title"][class*="u-typography--"] a, [data-component="title"][class*="u-typography--"] span, [data-component="text"][class*="u-typography--"] h1, [data-component="text"][class*="u-typography--"] h2, [data-component="text"][class*="u-typography--"] h3, [data-component="text"][class*="u-typography--"] h4, [data-component="text"][class*="u-typography--"] h5, [data-component="text"][class*="u-typography--"] h6, [data-component="text"][class*="u-typography--"] li, [data-component="text"][class*="u-typography--"] p, [data-component="text"][class*="u-typography--"] a, [data-component="text"][class*="u-typography--"] span {
  font: inherit;
}

.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);
}

@media (min-width: 768px) {
  .u-typography--h1 {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);
}

@media (min-width: 768px) {
  .u-typography--h2 {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);
}

@media (min-width: 768px) {
  .u-typography--h3 {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);
}

@media (min-width: 768px) {
  .u-typography--h4 {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5 {
  font: var(--emu-semantic-typography-narrow-headings-medium);
}

@media (min-width: 768px) {
  .u-typography--h5 {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-sm {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xs {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs, .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .content-container .emu-teaser__disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 768px) {
  .u-typography--body-xxs, .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .content-container .emu-teaser__disclaimer p {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

[data-component="text"][class*="u-font-family--"] h1, [data-component="text"][class*="u-font-family--"] h2, [data-component="text"][class*="u-font-family--"] h3, [data-component="text"][class*="u-font-family--"] h4, [data-component="text"][class*="u-font-family--"] h5, [data-component="text"][class*="u-font-family--"] h6, [data-component="text"][class*="u-font-family--"] li, [data-component="text"][class*="u-font-family--"] p, [data-component="text"][class*="u-font-family--"] a, [data-component="text"][class*="u-font-family--"] span, [data-component="title"][class*="u-font-family--"] h1, [data-component="title"][class*="u-font-family--"] h2, [data-component="title"][class*="u-font-family--"] h3, [data-component="title"][class*="u-font-family--"] h4, [data-component="title"][class*="u-font-family--"] h5, [data-component="title"][class*="u-font-family--"] h6, [data-component="title"][class*="u-font-family--"] li, [data-component="title"][class*="u-font-family--"] p, [data-component="title"][class*="u-font-family--"] a, [data-component="title"][class*="u-font-family--"] span {
  font-family: inherit;
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

.u-container--max {
  max-width: var(--emu-common-sizing-container) !important;
  float: unset !important;
  clear: both !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-fancy-border:before, .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .title-lockup:before {
  content: "";
  border-top: var(--emu-common-border-width-thin) solid currentColor;
  width: 80px;
  padding-bottom: 10px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .u-fancy-border:before, .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .title-lockup:before {
    width: 180px;
    padding-bottom: 25px;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container {
    flex-direction: column-reverse;
    display: flex;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__description {
    padding-top: 9px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__action-container .emu-button-v2 {
    padding-left: 38px;
    padding-right: 38px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .emu-teaser__image img {
    object-fit: contain;
    object-position: calc(50% + 3px);
    max-height: 220px;
  }

  .u-teaser--mobile-text-inline.emu-teaser.emu-teaser-v3.inline-text-overlay .teaser-container .content-container {
    padding-bottom: 17px;
    position: static;
  }
}

.u-teaser-disclaimer-text-color--dark .content-container .emu-teaser__disclaimer p {
  color: var(--emu-semantic-colors-secondary-dark);
}

.u-form-2-col {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .u-form-2-col {
    flex-direction: row;
    gap: 17px;
  }
}

.u-form-2-col .text {
  flex: 1;
}

@media (max-width: 767px) {
  .u-form-el-stacked-in-mobile {
    flex-direction: column;
    display: flex;
  }

  .u-form-el-stacked-in-mobile.emu-form-radio[data-layout="inline"], .u-form-el-stacked-in-mobile.emu-form-checkbox[data-layout="inline"] {
    gap: var(--emu-common-spacing-none);
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo svg {
  display: block;
}

.teaser .emu-teaser__action-container {
  margin-top: 31px;
}

.teaser .emu-teaser__action-container .emu-button-v2 {
  text-align: center;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser__action-container .emu-button-v2 {
    padding-top: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-medium);
    letter-spacing: -.1px;
  }
}

@media (min-width: 1200px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .teaser-image-container {
    flex: none;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .teaser-image-container a {
  pointer-events: none;
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .emu-teaser__image .cmp-image img {
  object-fit: contain;
}

@media (min-width: 768px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .emu-teaser__image .cmp-image img {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .emu-teaser__image .cmp-image img {
    max-height: 450px;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .content-container {
  position: static;
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .content-container .emu-teaser__disclaimer {
  padding: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-small);
  bottom: var(--emu-common-spacing-small);
  position: absolute;
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero .teaser-container .content-container .emu-teaser__disclaimer {
    right: var(--emu-common-spacing-medium);
    bottom: var(--emu-common-spacing-medium);
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 768px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container {
    flex-direction: row-reverse;
  }
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container {
    min-height: 450px;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container {
  align-self: center;
}

@media (max-width: 767px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .teaser-image-container {
    flex: 1;
  }
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .teaser-container .emu-teaser__image .cmp-image {
    justify-content: flex-end;
    display: flex;
  }

  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content {
    padding-top: 60px;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below .content-container {
  padding: var(--emu-component-cards-teaser-padding-narrow);
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content-container {
    flex: 1;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content-container {
    padding: var(--emu-component-cards-teaser-padding-wide);
    flex: 1;
    width: auto;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .emu-teaser__title {
  font-family: var(--emu-common-font-families-sans);
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .emu-teaser__title {
    padding-bottom: 10px;
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .emu-teaser__subtitle {
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-primary-light);
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-below .content .emu-teaser__subtitle {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-overlay .content-container {
  padding-top: var(--emu-common-spacing-large);
}

@media (min-width: 1024px) {
  .teaser .emu-teaser.emu-teaser-v3.inline-text-overlay .content-container {
    padding-left: 253px;
    padding-right: 234px;
    padding-top: var(--emu-common-spacing-large);
  }
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-overlay .emu-teaser__description {
  padding-top: var(--emu-common-spacing-small);
  max-width: 960px;
}

.teaser .emu-teaser.emu-teaser-v3.inline-text-overlay .emu-teaser__description p {
  font-weight: 300;
}

.testimonial__inner {
  padding-top: var(--emu-common-spacing-xl);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: 94px;
  padding-left: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  .testimonial__inner {
    padding-left: var(--emu-common-spacing-medium);
    display: flex;
  }
}

@media (min-width: 1024px) {
  .testimonial__inner {
    flex-direction: row;
    padding-top: 104px;
    padding-bottom: 62px;
  }
}

.testimonial__inner > .container:first-child {
  max-width: 431px;
}

@media (min-width: 768px) {
  .testimonial__inner > .container:first-child {
    margin-right: 30px;
  }
}

@media (min-width: 1024px) {
  .testimonial__inner > .container:first-child {
    margin-right: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  .testimonial__inner > .container:first-child {
    margin-right: 45px;
  }
}

.testimonial__inner > .container:last-child {
  flex: 1;
}

.testimonial__section--content {
  padding-right: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  .testimonial__section--carousel .tns-ovh {
    max-width: 422px;
  }
}

@media (min-width: 1024px) {
  .testimonial__section--carousel .tns-ovh {
    max-width: 948px;
  }

  .testimonial__section--carousel .emu-carousel__content {
    margin-left: -10px;
  }
}

.testimonial__section--carousel .emu-carousel__action-next {
  right: 10px;
}

@media (min-width: 1024px) {
  .testimonial__section--carousel .emu-carousel__action-next {
    right: 25%;
  }
}

@media (min-width: 1200px) {
  .testimonial__section--carousel .emu-carousel__action-next {
    right: 17%;
  }
}

@media (min-width: 1290px) {
  .testimonial__section--carousel .emu-carousel__action-next {
    right: 17px;
  }
}

.testimonial__title {
  margin-bottom: var(--emu-common-spacing-medium);
}

.testimonial__txt {
  margin-bottom: var(--emu-common-spacing-large);
}

.testimonial__video .plyr__control.plyr__control--overlaid {
  bottom: -16px;
  right: -12px;
}

@media (min-width: 1024px) {
  .testimonial__video .plyr__control.plyr__control--overlaid {
    bottom: -7px;
    right: -9px;
  }
}

.testimonial__video .plyr__video-embed {
  overflow: hidden;
}

.title-hero__inner {
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: var(--emu-common-spacing-large);
  padding-left: 37px;
  padding-right: 37px;
}

@media (min-width: 1024px) {
  .title-hero__inner {
    padding-top: 203px;
    padding-bottom: 137px;
    padding-left: 135px;
  }

  .title-hero__main.u-fancy-border:before {
    padding-bottom: 27px;
  }
}

.title-hero__sub {
  padding-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-light);
}

@media (min-width: 1024px) {
  .title-hero__sub {
    padding-top: 10px;
  }
}

/*# sourceMappingURL=main.css.map */
