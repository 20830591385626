.flippable-cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--emu-common-spacing-medium);

  @include mq('large') {
    justify-content: left;
  }
}

.flippable-card {
  position: relative;
  width: 339px; // to match figma
  overflow: hidden;

  @include mq('xx-large') {
    width: 340px; // as per figma
  }

  @mixin flippableCardActive {
    .flippable-card__face--back {
      opacity: 1;
      pointer-events: initial;
    }

    .flippable-card__face--front {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (pointer: fine) {
    &:hover {
      @include flippableCardActive;
    }
  }

  @media (pointer: coarse) {
    &.flippable-card--click-active {
      @include flippableCardActive;
    }
  }

  &__face {
    transition: all var(--emu-common-other-time-duration-instant) linear;

    &--back {
      opacity: 0;
      pointer-events: none;
    }

    &--abs {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
    }

    @include aem-editor-view {
      opacity: 1;
      position: static;
    }
  }

  &__content {
    padding-left: var(--emu-common-spacing-large);
    padding-right: 22px;
    padding-bottom: 29px;
    padding-top: 29px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include aem-editor-view {
      opacity: 1;
      position: static;
    }

    // link container
    > div:last-child {
      margin-top: auto;
    }

    > div:nth-child(2) {
      flex: 1;
      overflow: auto;
      margin-bottom: 10px;
      padding-right: 10px;
      scrollbar-color: var(--emu-semantic-colors-primary-500) rgba(0, 0, 0, 0.4);
      scrollbar-width: thin;
    }
  }

  &__survey-data {
    margin-top: 10px;
  }

  &__logo {
    margin-bottom: 37px;
    min-height: 60px;

    @include aem-editor-view {
      opacity: 1;
      position: static;
    }

    img {
      width: auto;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    line-height: 20px;

    a {
      text-underline-offset: 3px;
      align-items: center;
      display: flex;

      img {
        margin-left: var(--emu-common-spacing-small);
      }
    }
  }

  &__footnotes {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-right: var(--emu-common-spacing-large);
    margin-bottom: var(--emu-common-spacing-large);
    margin-left: var(--emu-common-spacing-large);

    @include aem-editor-view {
      position: static !important;
      color: var(
        --emu-common-colors-black
      ) !important; // needed to override in author mode
    }
  }
}
