#rising-star {
  .rising-star {
    &__hero {
      .teaser
        .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero
        .content-container {
        padding-top: 24px;
        padding-bottom: 33px;

        @include mq('medium') {
          padding-top: 34px;
          padding-bottom: 31px;
        }
      }
    }

    &__intro {
      .page-intro {
        &__wrapper {
          padding-right: 37px;
          padding-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            padding-right: 119px;
            padding-top: var(--emu-common-spacing-xl);
            padding-bottom: var(--emu-common-spacing-none);
          }
        }

        &__cta.emu-button-v2.emu-button-v2.emu-button-v2 {
          max-width: 220px;
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-medium);
        }

        &__text {
          p {
            padding-right: var(--emu-common-spacing-none);
          }
        }

        &__img {
          @include mq('large', 'max-width') {
            margin-top: 30px;
          }

          img {
            width: 168px;

            @include mq('large') {
              width: 266px;
            }
          }
        }
      }
    }

    &__programs {
      .catalog {
        &__inner {
          @include mq('medium') {
            padding-top: 63px;
          }

          .intro-group__item-spacing-block {
            margin-right: var(--emu-common-spacing-small);

            @include mq('large') {
              margin-right: 15px;
            }
          }
        }

        &__items-container {
          margin-bottom: 65px;

          @include mq('medium') {
            margin-bottom: 48px;
          }
        }

        &__title {
          margin-bottom: 66px;
        }

        &__section {
          &-title {
            margin-bottom: 17px;

            @include mq('medium') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }

        &__disc-txt {
          padding-right: var(--emu-common-spacing-none);

          p + p {
            margin-top: 12px;

            @include mq('medium') {
              margin-top: 10px;
            }
          }
        }

        &__kit {
          &-section {
            @include mq('large') {
              padding-right: 15px;
            }

            > .image {
              @include mq('large') {
                flex-shrink: 0;
              }
            }
          }

          &-desc {
            @include mq('large') {
              max-width: 895px; //as per figma
              width: 100%;
            }
          }
        }

        &__kit-desc {
          letter-spacing: -0.14px;

          @include mq('medium') {
            letter-spacing: normal;
          }
        }
      }

      &-separator {
        height: 2px;
        margin-right: var(--emu-common-spacing-small);
        margin-left: var(--emu-common-spacing-small);

        @include mq('large') {
          margin-right: 15px;
          margin-left: 15px;
        }
      }
    }

    &__enroll {
      .gen-form {
        &__inner {
          gap: 61px;
          padding-bottom: var(--emu-common-spacing-xl);

          @include mq('large') {
            padding-bottom: 62px;
            gap: 62px;
          }

          > .container:first-child {
            @include mq('large') {
              flex-basis: 648px; // as per figma
            }
          }
        }

        &__title {
          margin-bottom: 34px;
        }

        &__sub-title {
          padding-bottom: var(--emu-common-spacing-large);

          @include mq('large') {
            padding-bottom: 31px;
          }
        }

        &__content {
          &-block {
            padding-bottom: var(--emu-common-spacing-large);

            &--step-2,
            &--step-5 {
              padding-bottom: 30px;

              @include mq('large') {
                padding-bottom: 31px;
              }
            }

            &--text-spacing {
              letter-spacing: 0.01px;
            }
          }

          &-title {
            margin-bottom: var(--emu-common-spacing-small);

            .emphasis {
              color: var(--emu-common-colors-medium-brown-500);
            }
          }
        }

        &__section {
          &--content {
            .container:last-child {
              .gen-form__content-block {
                padding-bottom: var(--emu-common-spacing-none);
              }
            }
          }

          &--form {
            padding-top: 65px;
            padding-bottom: var(--emu-common-spacing-xl);
            padding-right: 29px;
            padding-left: 29px;

            @include mq('medium') {
              padding-top: var(--emu-common-spacing-xl);
              padding-right: var(--emu-common-spacing-large);
              padding-left: var(--emu-common-spacing-large);
            }
          }
        }

        &__form {
          &-heading {
            padding-bottom: var(--emu-common-spacing-xxs);

            @include mq('medium') {
              padding-bottom: var(--emu-common-spacing-none);
            }
          }

          &-help-message {
            margin-top: -8px;
            margin-bottom: var(--emu-common-spacing-medium);

            @include mq('medium') {
              max-width: 87%; //to match design
            }
          }

          &-main {
            padding-bottom: 33px;

            .emu-form {
              &-text__info-text {
                margin-top: 10px;

                @include mq('medium') {
                  margin-top: 9px;
                }
              }

              &-textarea__textarea {
                min-height: 210px; //to match design

                @include mq('medium') {
                  min-height: 120px; //to match design
                }
              }

              &-radio {
                margin-top: var(--emu-common-spacing-none);
                margin-bottom: 15px;
              }

              &-dropdown {
                margin-left: var(--emu-common-spacing-none);
                margin-bottom: 15px;
                margin-right: var(--emu-common-spacing-none);

                .emu-dropdown-menu__button {
                  padding-right: 24px;

                  @include mq('medium') {
                    padding-right: 14px;
                  }
                }
              }

              &-checkbox {
                margin-top: var(--emu-common-spacing-medium);

                @include mq('medium') {
                  margin-top: 17px;
                }
              }

              &-recaptcha {
                margin-top: 21px;

                @include mq('medium') {
                  margin-top: 17px;
                  margin-bottom: 33px;
                }
              }
            }
          }

          &-location-fields {
            .text {
              flex: 0 0 50.5%;
            }

            .options {
              @include mq('medium') {
                max-width: 140px;
                flex: 1;
              }
            }

            .text:last-child {
              max-width: 140px;
              flex: 1;
            }

            .emu-form-dropdown {
              .emu-dropdown-menu__button {
                padding-right: 23px;
              }
            }
          }
        }
      }
    }
  }
}
