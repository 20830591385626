[data-component='container-isi-v2'] {
  position: relative;

  &.isi-scrollable {
    .isi-container {
      z-index: var(--emu-common-other-z-index-isi);
    }
  }

  // main content at the end of the page
  .aaaem-isi-container__isi-content > div:first-child > .container {
    @include max-container;
  }

  i {
    letter-spacing: -0.2px; // to match figma
  }

  .gen-isi {
    &__container {
      border-top: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      .aaaem-isi-banner {
        padding: var(--emu-component-containers-isi-header-padding-top-narrow)
        var(--emu-component-containers-isi-header-padding-right-narrow)
        var(--emu-component-containers-isi-header-padding-bottom-narrow)
        var(--emu-component-containers-isi-header-padding-left-narrow);

      @include mq('large') {
        padding: var(--emu-component-containers-isi-header-padding-top-wide)
          var(--emu-component-containers-isi-header-padding-right-wide)
          var(--emu-component-containers-isi-header-padding-bottom-wide)
          var(--emu-component-containers-isi-header-padding-left-wide);
      }

        @include max-container;
        align-items: normal;

        .aaaem-isi-container {
          &__banner-content {
            -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
            display: block !important; // when expanded, AAAEM hides this section. Making sure that it is not hidden
            cursor: pointer;
            padding-right: var(--emu-common-spacing-xs);
            flex: 1;

            p {
              font-size: var(--emu-semantic-font-sizes-narrow-xs);
              font-weight: var(--emu-common-font-weight-regular);
              line-height: 20px;
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('large'){
                font-size: 21px;
              }
            }
          }

          &__button-wrapper {
            -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
            align-self: stretch;

            > div {
              height: 100%;
            }

            .cmp-button {
              flex-direction: row-reverse;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
              padding-top: var(--emu-common-spacing-none);
              padding-right: var(--emu-common-spacing-medium);
              padding-bottom: var(--emu-common-spacing-none);
              padding-left: var(--emu-common-spacing-medium);

              .aaaem-button__icon {
                width: 15px;
                height: 15px;
                margin-right: var(--emu-common-spacing-none);

                &::before,
                &::after {
                  background-color: var(--emu-semantic-colors-primary-dark);
                  border-radius: var(--emu-common-border-radius-xs);
                }
              }

              .cmp-button__text {
                color: var(--emu-semantic-colors-primary-dark);
                display: block;
                min-width: 88px;
                text-align: left;
                font-size: var(--emu-semantic-font-sizes-narrow-xs);
                line-height: 22px;
                margin-right: 10px;
                margin-bottom: var(--emu-common-spacing-none);
                white-space: nowrap;
              }

              &:active {
                outline: none;
              }
            }
          }
        }
      }
    }

    &__content {
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: var(--emu-common-spacing-xl);

      p {
        a {
          word-break: break-all;
        }

        i {
          @include mq('large') {
            display: inline-block;
            transform: skewX(8deg); // to match figma
          }
        }
      }

      &--botox-cosmetic {
        margin-bottom: var(--emu-common-spacing-large);
      }

      &--juvederm {
        p {
          .emphasis {
            display: block;
            margin-top: var(--emu-common-spacing-large);
          }
        }
      }

      &--strattice {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__heading {
      .cmp-title__text {
        color: var(--emu-common-colors-dark-brown-500);

        // media query needed, as smaller screens are showing horizontal scrollbar coz of this text
        @include mq('359px', 'max-width') {
          font-size: var(--emu-semantic-font-sizes-narrow-xs); // intentional, to avoid horizontal scrollbar
          word-break: break-word;
          letter-spacing: normal;
        }
      }

      i {
        display: inline-block;
        transform: skewX(8deg); // to match figma
        padding-right: 3px; // to match figma
      }
    }

    &__title {
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: 10px;

      .emu-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: 20px;
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &__info {
      p,
      li {
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: 20px;
        margin-top: var(--emu-common-spacing-none);
      }

      p {
        font-weight: var(--emu-common-font-weight-regular);

        & + p {
          margin-top: 12px; // to match figma
        }

        i {
          padding-right: 1.5px; // to match figma

          @include mq('large') {
            letter-spacing: -0.25px; // to match figma
          }
        }
      }

      ul {
        margin-top: 13px; // to match figma
        margin-bottom: 12px;
        padding-left: 24px; // to match figma
        list-style: none;

        @include mq('large') {
          margin-top: 12px; // to match figma
        }
      }

      li {
        position: relative;

        &::before {
          content: '';
          background-color: currentColor;
          border-radius: 50%;
          width: 4px;
          height: 4px;
          position: absolute;
          top: 7px;
          left: -14px;
        }
      }
    }

    &__warnings-box {
      line-height: 20px;
      padding-top: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      padding-bottom: 30px; // to match figma
      padding-left: var(--emu-common-spacing-large);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        padding-top: 29px; // to match figma
        padding-bottom: var(--emu-common-spacing-large);
      }

      p {
        &:not(:last-child) {
          margin-bottom: var(--emu-common-spacing-small); // to match fimga
        }
      }

      ul {
        padding-left: 18px; // to match figma
      }

      li {
        letter-spacing: 0.3px; // to match figma

        &:not(:last-child) {
          margin-bottom: 11px;
        }

        @include mq('large') {
          margin-bottom: 11px; //to match  figma
        }
      }

      &--natrelle {
        margin-top: var(--emu-common-spacing-large);

        ul {
          margin-top: 10px; // to match figma
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: 22px; // to match figma

          @include mq('large') {
            margin-top: 13px; // to match figma
          }
        }

        li {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          letter-spacing: 0;

          &:not(:last-child) {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}