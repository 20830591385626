#home {
  .home {
    &__hero {
      position: relative;
      background-size: 109px !important; // needed to override inline styles added by AEM
      background-position: center 13px;
      background-repeat: no-repeat;

      @include mq('medium') {
        background-position: 80px 50%;
      }

      @include mq('large') {
        background-position: 74px calc(50% + 32px);
        background-size: 345px !important; // needed to override inline styles added by AEM
      }

      @include mq('xx-large') {
        background-position: calc(50vw - 720px + 74px) calc(50% + 32px);
      }

      &-teaser {
        &.emu-teaser.emu-teaser-v3.inline-text-below {
          .content-container {
            position: static;
            padding-top: 28px;
            padding-bottom: 36px;

            @include mq('large') {
              padding-left: 135px;
              padding-top: 79px;
              max-width: 491px; //as per figma
            }

            .emu-teaser__disclaimer {
              padding: var(--emu-common-spacing-none);
              position: absolute;
              right: var(--emu-common-spacing-small);
              bottom: var(--emu-common-spacing-small);

              @include mq('large') {
                right: var(--emu-common-spacing-medium);
                bottom: var(--emu-common-spacing-medium);
              }

              p {
                @extend .u-typography--body-xxs;
              }
            }
          }

          .teaser-container {
            .teaser-image-container {
              @include mq('large') {
                align-self: stretch;
              }

              a {
                pointer-events: none;
              }

              // intentional to make sure all the divs inside are getting a 100%
              div {
                @include mq('large') {
                  height: 100%;
                }
              }
            }

            .emu-teaser__image {
              img {
                @include mq('large') {
                  object-fit: cover;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }

    &__program {
      &-inner {
        padding-top: 30px;
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-large);
        padding-left: var(--emu-common-spacing-small);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-xl);
          padding-right: 135px;
          padding-bottom: var(--emu-common-spacing-xl);
          padding-left: 135px;
        }
      }

      &-spacing-block {
        margin-top: 36px;
        margin-bottom: 36px;
        padding-top: 36px;
        padding-bottom: 35px;
        border-top: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-primary-500);
        border-bottom: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-primary-500);

        @include mq('large') {
          margin-top: var(--emu-common-spacing-xl);
          margin-bottom: var(--emu-common-spacing-xl);
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: var(--emu-common-spacing-xl);
        }
      }

      &-card {
        &--rising-star {
          .brand-card {
            &__cta {
              padding-right: 26px;
            }
          }
        }

        &--ami,
        &--exp {
          > div.container {
            &:first-child {
              @include mq('large') {
                margin-right: var(--emu-common-spacing-medium);
              }
            }
          }
        }

        &--exp {
          .brand-card {
            &__txt {
              @include mq('large') {
                margin-bottom: 31px;
              }
            }

            &__cta {
              min-width: 0;
              padding-left: 22px;
              padding-right: 22px;

              @include mq('large') {
                min-width: 250px;
              }
            }
          }
        }
      }

      &-promo-video {
        @include mq('large') {
          margin-top: var(--emu-common-spacing-medium);
        }
      }
    }

    &__products {
      &-inner {
        padding-top: var(--emu-common-spacing-xl);
        padding-left: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-xl);

        @include mq('large') {
          padding-right: var(--emu-common-spacing-medium);
          padding-bottom: 48px;
          padding-left: var(--emu-common-spacing-medium);
        }
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-large);
      }

      &-grid {
        margin-bottom: 48px;
      }

      .flippable-card {
        &__img {
          img {
            height: 400px; // as per the design
          }
        }
      }

      &-cta-container {
        display: flex;
        justify-content: center;
      }

      &__cta {
        justify-content: center;
        text-wrap: nowrap;
        padding-top: var(--emu-common-spacing-medium);
        padding-bottom: var(--emu-common-spacing-medium);
        padding-left: 12px;
        padding-right: 12px;

        @include mq('large') {
          min-width: 365px; //as per figma
        }
      }
    }

    &__references {
      width: 100%;
      max-width: 1052px; // as per figma
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: 48px;

      p {
        line-height: 20px; // to match figma
      }
    }

    &__support {
      &-teaser {
        .teaser {
          .emu-teaser.emu-teaser-v3 {
            .content-container {
              .content-middle-left {
                overflow: hidden;
              }
            }
          }
        }
      }

      &-teaser-cta {
        padding-left: 54.5px;
        padding-right: 54.5px;

        @include mq('large') {
          padding-left: 23.5px;
          padding-right: 23.5px;
        }

        .cmp-button__text {
          @include mq('large') {
            letter-spacing: -0.03px; // to match figma
          }
        }
      }
    }

    &__equity-teaser {
      .emu-teaser {
        .content-container {
          @include mq('large') {
            padding-top: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: 135px;
          }
        }

        &__description {
          max-width: 700px;
        }

        &__action-container {
          .emu-button-v2 {
            @include mq('large') {
              padding-left: 22px;
              padding-right: 22px;
              padding-top: var(--emu-common-spacing-medium);
              padding-bottom: var(--emu-common-spacing-medium);
              letter-spacing: 0.1px;
            }
          }
        }
      }
    }
  }
}
