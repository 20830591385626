.testimonial {
  &__inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 94px;
    padding-left: var(--emu-common-spacing-small);

    @include mq('medium') {
      display: flex;
      padding-left: var(--emu-common-spacing-medium);
    }

    @include mq('large') {
      padding-top: 104px;
      padding-bottom: 62px;
      flex-direction: row;
    }

    > .container:first-child {
      max-width: 431px;
      
      @include mq('medium') {
        margin-right: 30px;
      }

      @include mq('large') {
        margin-right: var(--emu-common-spacing-none);
      }

      @include mq('x-large') {
        margin-right: 45px;
      }
    }

    > .container:last-child {
      flex: 1;
    }
  }

  &__section {
    &--content {
      padding-right: var(--emu-common-spacing-small);
    }

    &--carousel {
      .tns-ovh {
        @include mq('medium') {
          max-width: 422px;
        }

        @include mq('large') {
          max-width: 948px;
        }
      }

      .emu-carousel {
        &__content {
          @include mq('large') {
            margin-left: -10px; // to match the design more closely
          }
        }

        &__action {
          &-next {
            right: 10px;

            @include mq('large') {
              right: 25%;
            }

            @include mq('x-large') {
              right: 17%;
            }

            @include mq('1290px') {
              right: 17px;
            }
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-medium);
  }

  &__txt {
    margin-bottom: var(--emu-common-spacing-large);
  }

  &__video {
    .plyr__control.plyr__control--overlaid {
      right: -12px; //to match with the figma design
      bottom: -16px;

      @include mq('large') {
        right: -9px;
        bottom: -7px;
      }
    }

    .plyr__video-embed {
      overflow: hidden;

      @include mq('medium') {
      }
    }
  }
}
