#education {
  .teaser {
    .u-teaser--as-hero {
      .content-container {
        padding-top: 25px;

        @include mq('large', 'max-width') {
          padding-bottom: var(--emu-common-spacing-large);
        }

        @include mq('x-large') {
          padding: var(--emu-component-cards-teaser-padding-wide);
        }
      }
    }

    .emu-teaser__image .cmp-image img {
      @include mq('medium') {
        width: auto;
      }
    }
  }

  .page-intro {
    &--with-logo {
      .page-intro__wrapper {
        @include mq('large') {
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: 75px; //to match figma
        }
      }

      .page-intro__img {
        img {
          max-width: 45%;
          width: 100%;
          margin-left: auto;
          margin-right: auto;

          @include mq('large') {
            max-width: 85%;
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__text {
      &.cmp-text {
        .emphasis {
          margin-bottom: 14px;
        }
      }
    }

    &__content-block-spacing-block {
      border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-light);

      @include mq('large', 'max-width') {
        padding-bottom: 31px; // to match figma
      }
    }

    &__cta {
      @include mq('large', 'max-width') {
        margin-top: 30px; // to match figma
      }
    }
  }
}
