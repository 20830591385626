@import '../../styles/global/g-utilities';
.demo-code {
  background-color: var(--emu-common-colors-black);
  color: orange !important;
  border-radius: var(--emu-common-border-radius-xs);
  padding: var(--emu-common-spacing-xxs) var(--emu-common-spacing-xs);
  font-size: 14px;
}

.demo-section {
  &__main-title {
    overflow: hidden;
    width: 100%;
    @extend .u-fancy-border;
  }

  &__item {
    border-bottom: 1px solid #a1a1a1;
    @include max-container;
  }

  &__txt {
    h2,
    h4 {
      display: inline-flex;
      line-height: 150%;
      letter-spacing: 0.5px;
    }

    h2 {
      font: var(--emu-semantic-typography-wide-headings-medium);
      border-bottom: var(--emu-common-border-width-thick) solid
        var(--emu-semantic-colors-primary-dark);
    }

    h4 {
      font-size: 18px;
      text-decoration: underline;
      text-decoration-color: var(--emu-semantic-colors-primary-dark);
      text-decoration-thickness: 2px;
    }

    ul {
      padding-left: 20px;
    }

    p,
    li {
      color: #444;
      font-size: 16px;
    }

    u {
      @extend .demo-code;
      text-decoration: none;
    }

    &--list-as-code {
      ul {
        display: flex;
        gap: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-left: var(--emu-common-spacing-none);
      }

      li {
        @extend .demo-code;
        margin-top: var(--emu-common-spacing-none);
        list-style: none;
      }
    }
  }

  &__preview {
    @include aem-editor-view {
      position: relative;
      outline: 2px dashed var(--emu-semantic-colors-primary-dark);
      overflow: hidden;

      &::after {
        content: 'COPY THE CONTENTS INSIDE THIS DOTTED CONTAINER';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--emu-semantic-colors-primary-dark);
        text-align: center;
        min-height: 40px;
        line-height: 40px;
        color: var(--emu-common-colors-white);
      }

      > div:first-child {
        margin-top: 55px;
      }
    }
  }
}
