header.experiencefragment {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);

  @include mq('large') {
    position: fixed;
  }
}

.gen-header {
  border-bottom: var(--emu-common-border-width-thin) solid
    var(--emu-common-colors-medium-brown-500);
  background-color: var(--emu-common-colors-dark-brown-500);
  border-bottom: none;
  width: 100%;
  transition: background 0.5s ease;

  @include mq('large') {
    background: linear-gradient(
      0deg,
      rgba(73, 39, 40, 0) 0%,
      var(--emu-common-colors-dark-brown-500) 100%
    );
  }

  &__inner {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
    }

    @include mq('large', 'max-width') {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-medium-brown-500);
      }
    }

    > .container {
      @include mq('large') {
        @include max-container;
      }
    }
  }

  &__main {
    @include mq('large') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > .container {
      &:first-child {
        @include mq('large') {
          flex-shrink: 0;
          margin-right: var(--emu-common-spacing-medium);
        }
      }

      &:last-child {
        @include mq('large') {
          max-width: 768px; // as per design
          width: 100%;
        }
      }
    }
  }

  &__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }

  &__toggler {
    &.emu-button-v2.emu-button {
      &:not(a) {
        padding: var(--emu-common-spacing-none);

        @include mq('large') {
          display: none;
        }

        &,
        &:hover,
        &:active,
        &:focus {
          background-color: var(--emu-common-colors-dark-brown-500);
          border-color: var(--emu-common-colors-dark-brown-500);
        }
      }
    }

    span {
      font-size: var(--emu-semantic-font-sizes-narrow-xxs);
      line-height: var(--emu-semantic-line-heights-narrow-xxs);
      font-weight: var(--emu-common-font-weight-light);
    }
  }

  &__nav-container {
    display: none;

    &.js-toggle-on {
      display: block;
    }

    @include mq('large') {
      display: block;
    }
  }

  &--background {
    @include mq('large') {
      background-color: var(--emu-common-colors-dark-brown-500);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-medium-brown-500);
    }
  }
}
