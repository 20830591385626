.intro-group {
  &__inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-right: 37px;
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: 37px;

    @include mq('large') {
      padding-right: 132px;
      padding-left: 132px;
    }
  }

  &__item {
    &-spacing-block {
      padding-bottom: var(--emu-common-spacing-xl);
      border-bottom: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-500);
      margin-bottom: var(--emu-common-spacing-xl);
    }
  }

  .page-intro {
    &__wrapper {
      padding: var(--emu-common-spacing-none);
    }

    &__content-wrapper {
      @include mq('large') {
        max-width: 816px; // to match figma
      }
    }

    &__title {
      margin-bottom: 18px;
    }

    &__text {
      @include mq('large') {
        max-width: 812px; // to match figma
      }

      p {
        padding-right: var(--emu-common-spacing-none);

        @include mq('large') {
          line-height: 25px; // to match design
        }

        a {
          color: var(--emu-common-colors-dark-brown-500);
        }
      }
    }
  }
}
