#error {
  .title-hero {
    &__inner {
      padding-top: 25px; // to match figma

      @include mq('large') {
        padding-top: 202px; // to match figma
      }
    }
  }

  .error {
    &__content {
      margin-bottom: var(--emu-common-spacing-medium);

      p {
        @extend .u-typography--h5;
        @extend .u-font-family--beatrice-reg;
      }

      &-wrapper {
        padding-left: 37px;
        padding-right: 37px;
        padding-top: var(--emu-common-spacing-large);
        padding-bottom: 118px;

        @include mq('large') {
          padding-left: 135px;
          padding-right: var(--emu-common-spacing-none);
          padding-top: var(--emu-common-spacing-xl);
          padding-bottom: 229px;
        }
      }

      &-inner {
        @include mq('large') {
          width: 808px; //as per figma
        }
      }
    }

    &__disc {
      margin-bottom: var(--emu-common-spacing-large);

      p {
        font-weight: inherit;
      }
    }

    &__cta {
      padding-left: 55.5px;
      padding-right: 55.5px;
      padding-top: 17px;
      padding-bottom: 17px;
    }

    &-500 {
      .title-hero {
        &__inner {
          @include mq('large') {
            padding-top: 175px;
            padding-bottom: 112px;
          }
        }
      }
    }
  }
}
