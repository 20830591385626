@import '../../styles/global/g-utilities';

.teaser {
  .emu-teaser {
    &__action-container {
      margin-top: 31px;

      .emu-button-v2 {
        padding-left: 22px;
        padding-right: 22px;
        text-align: center;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-medium);
          padding-bottom: var(--emu-common-spacing-medium);
          letter-spacing: -0.1px;
        }
      }
    }
  }

  .emu-teaser.emu-teaser-v3.inline-text-below {
    &.u-teaser--as-hero {
      .teaser {
        &-container {
          .teaser-image-container {
            @include mq('x-large') {
              flex: 0 0 auto;
            }

            a {
              pointer-events: none;
            }
          }

          .emu-teaser__image {
            .cmp-image {
              img {
                object-fit: contain;

                @include mq('medium') {
                  width: auto;
                }

                @include mq('large') {
                  max-height: 450px;
                }
              }
            }
          }

          .content-container {
            position: static;

            .emu-teaser__disclaimer {
              padding: var(--emu-common-spacing-none);
              position: absolute;
              right: var(--emu-common-spacing-small);
              bottom: var(--emu-common-spacing-small);

              @include mq('large') {
                right: var(--emu-common-spacing-medium);
                bottom: var(--emu-common-spacing-medium);
              }

              p {
                @extend .u-typography--body-xxs;
              }
            }
          }
        }
      }
    }

    .teaser {
      &-container {
        display: flex;
        flex-direction: column-reverse;

        @include mq('medium') {
          flex-direction: row-reverse;
        }

        @include mq('large') {
          min-height: 450px; // as per figma
        }

        .teaser-image-container {
          align-self: center;

          @include mq('medium','max-width'){
            width: 100%;
          }

          @include mq('x-large') {
            flex: 1;
          }
        }

        .emu-teaser__image {
          .cmp-image {
            @include mq('large') {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }

    .content {
      @include mq('large') {
        padding-top: 60px; // header height
      }

      &-container {
        padding: var(--emu-component-cards-teaser-padding-narrow);

        @include mq('large') {
          flex: 1;
          width: auto;
        }

        @include mq('x-large') {
          padding: var(--emu-component-cards-teaser-padding-wide);
          flex: 1;
          width: auto;
        }
      }

      .title-lockup {
        @extend .u-fancy-border;
      }

      .emu-teaser {
        &__title {
          font-family: var(--emu-common-font-families-sans);

          @include mq('large') {
            padding-bottom: 10px;
          }
        }

        &__subtitle {
          font-family: var(--emu-semantic-font-families-heading);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          color: var(--emu-semantic-colors-primary-light);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }
        }
      }
    }
  }

  .emu-teaser.emu-teaser-v3.inline-text-overlay {
    .content-container {
      padding-top: var(--emu-common-spacing-large);

      @include mq('large') {
        padding-left: 253px;
        padding-right: 234px;
        padding-top: var(--emu-common-spacing-large);
      }
    }

    .emu-teaser {
      &__description {
        padding-top: var(--emu-common-spacing-small);
        max-width: 960px; // to make it look better

        p {
          font-weight: 300;
        }
      }
    }
  }
}
