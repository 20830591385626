#start {
  .page-intro {
    &__wrapper {
      padding-right: 37px;
      padding-top: 34px;

      @include mq('large') {
        padding-top: 65px;
        padding-right: 137px;
      }
    }

    &__text {
      p {
        padding-right: var(--emu-common-spacing-none);
        line-height: 25px; //to match figma
      }

      u {
        text-underline-offset: 2px;

        @include mq('large') {
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .start {
    &__hero {
      .content-container {
        @include mq('large', 'max-width') {
          padding-top: 25px;
        }
      }
    }

    &__rpd {
      &-inner {
        padding-top: var(--emu-common-spacing-xl);
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-xl);
        padding-left: var(--emu-common-spacing-small);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-xl);
          padding-right: 135px;
          padding-bottom: var(--emu-common-spacing-xl);
          padding-left: 135px;
        }
      }

      &-content {
        padding-left: 29px;
        padding-right: 29px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-large);
      }

      &-txt {
        margin-bottom: 18px; //to match figma

        @include mq('large') {
          margin-bottom: 12px;
        }

        ul {
          padding-left: 17px; //to match figma
        }

        li {
          padding-left: var(--emu-common-spacing-small); //to match figma
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
          position: relative;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-medium);
            line-height: 32px;
          }

          & + li {
            margin-top: 14px; //to match figma

            @include mq('large') {
              margin-top: 13px; //to match figma
            }
          }

          &::before {
            content: '';
            background-color: currentColor;
            border-radius: 50%;
            width: 4px;
            height: 4px;
            position: absolute;
            top: 11px;
            left: -16px;

            @include mq('large') {
              top: 15px;
              left: -15px;
            }
          }
        }
      }

      &-disc {
        margin-bottom: var(--emu-common-spacing-xl);

        @include mq('large') {
          margin-bottom: 54px;
        }
      }

      &-teaser {
        .cmp-teaser {
          &__title {
            font-size: var(--emu-semantic-font-sizes-narrow-xxl);
            line-height: var(--emu-semantic-line-heights-narrow-xxl);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-xxl);
              line-height: var(--emu-semantic-line-heights-wide-xxl);
            }
          }

          &__description {
            p {
              display: block !important; // to override the inline styles given by AEM

              @extend .u-font-family--beatrice-reg;
              @extend .u-typography--h5;

              & + p {
                margin-top: 14px; //to match figma
              }
            }
          }
        }

        .emu-teaser {
          &__description {
            padding-top: 17px;

            @include mq('large') {
              padding-top: var(--emu-common-spacing-medium);
            }
          }

          &__subtext {
            padding-top: 17px;
            letter-spacing: -0.15px;

            @include mq('large') {
              letter-spacing: normal;
            }
          }

          &__disclaimer {
            padding-top: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-none);
            padding-left: var(--emu-common-spacing-none);
            letter-spacing: -0.15px;

            @include mq('large') {
              letter-spacing: normal;
            }

            p {
              white-space: nowrap;
              font-size: var(--emu-semantic-font-sizes-narrow-xxs);
              line-height: var(--emu-semantic-line-heights-narrow-xxs);
            }
          }
        }

        .content-container {
          padding-top: var(--emu-common-spacing-xl);
          padding-right: 30px;
          padding-bottom: 63px;
          padding-left: 29px;

          @include mq('large') {
            padding-right: 121px;
            padding-bottom: var(--emu-common-spacing-xl);
            padding-left: 118px;
          }
        }
      }
    }

    &__ami-banner {
      &-inner {
        padding-top: 52px;
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-xl);
        padding-left: var(--emu-common-spacing-small);

        @include mq('medium') {
          padding-top: var(--emu-common-spacing-xl);
          padding-right: var(--emu-common-spacing-medium);
          padding-left: var(--emu-common-spacing-medium);
          display: flex;
          flex-direction: row;
          gap: 22px;
          align-items: center;
        }

        > .container:first-child {
          flex: 1;
        }
      }

      &-section {
        &--content {
          margin-top: 13px;
        }
      }

      &-pretitle {
        margin-bottom: 9px;

        @include mq('medium') {
          margin-bottom: var(--emu-common-spacing-medium);
          margin-top: 18px;
        }
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }

    &__course-diff {
      &-inner {
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-xl);
        padding-left: var(--emu-common-spacing-small);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-medium);
          padding-right: var(--emu-common-spacing-none);
          padding-bottom: 62px;
        }
      }

      &-section-wrapper {
        @include mq('large') {
          display: flex;
          margin-right: var(--emu-common-spacing-medium);
        }
      }

      &-section {
        &--left {
          @include mq('large') {
            margin-right: 15px;
          }
        }

        &--right {
          .start__course-diff-item-wrapper {
            > .container {
              &:first-child {
                @include mq('large') {
                  margin-right: var(--emu-common-spacing-medium);
                }
              }
            }
          }
        }
      }

      &-title {
        padding-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          border-bottom: var(--emu-common-border-width-medium) solid
            var(--emu-semantic-colors-secondary-dark);
        }
      }

      &-subtitle {
        margin-bottom: 17px;

        @include mq('large') {
          min-height: 62px; //as per figma
        }
      }

      &-txt {
        p {
          & + p {
            margin-top: 13px;
          }
        }

        a {
          display: inline-block;
          color: var(--emu-semantic-colors-primary-dark);
          font-weight: var(--emu-common-font-weight-regular);
          line-height: 20px;
        }
      }

      &-item-disc {
        padding-top: 13px;
        padding-bottom: 43px; //to match figma
        line-height: 20px;

        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-none);
        }

        ul {
          padding-left: 21px; //to match figma
          margin-top: 9px; //to match figma
          margin-bottom: 11px;
          list-style: none;

          @include mq('large') {
            margin-top: 10px;
          }
        }

        li {
          position: relative;

          & + li {
            margin-top: var(--emu-common-spacing-none); //to match figma
          }

          &::before {
            content: '';
            background-color: currentColor;
            border-radius: 50%;
            width: 3px;
            height: 3px;
            position: absolute;
            top: 7px;
            left: -12px;
          }
        }
      }

      &-item-wrapper {
        @include mq('large') {
          display: flex;
        }
      }

      &-item {
        padding-top: var(--emu-common-spacing-large);
        padding-bottom: 61px;
        border-top: var(--emu-common-border-width-medium) solid
          var(--emu-semantic-colors-secondary-dark);

        @include mq('large') {
          max-width: 459px; //as per figma
          width: 100%;
          border-top: none;
        }

        &--ami {
          padding-bottom: var(--emu-common-spacing-none);
        }
      }

      &-disc {
        margin-top: var(--emu-common-spacing-xxs);

        @include mq('large') {
          margin-top: 28px;
        }

        a {
          display: inline-block;
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }
  }
}
