.brand-card {
  @include mq('large') {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--reverse-in-mobile {
    @include mq('large', 'max-width') {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__asset {
    @include mq('large') {
      height: 100%;
    }
  }

  &__img {
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;

      @include mq('large', 'max-width') {
        display: block;
        width: 100%;
      }
    }
  }

  &__content {
    padding-top: 33px;
    padding-right: 30px;
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 29px;

    @include mq('large') {
      padding-top: var(--emu-common-spacing-xl);
      padding-right: 63px;
      padding-bottom: var(--emu-common-spacing-xl);
      padding-left: var(--emu-common-spacing-xl);
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__title {
    margin-bottom: 9px;
  }

  &__txt {
    margin-bottom: 31px;

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
    }

    p,
    li {
      font-weight: inherit;
      line-height: 25px;
    }
  }

  .button {
    .emu-button-v2 {
      &.emu-button-v2.emu-button-v2 {
        padding-top: var(--emu-common-spacing-medium);
        padding-right: 24px;
        padding-bottom: var(--emu-common-spacing-medium);
        padding-left: 24px;
      }
    }
  }

  &__cta {
    justify-content: center;

    @include mq('large') {
      min-width: 300px; // as per design
    }
  }

  &--desk-half-split {
    margin-bottom: var(--emu-common-spacing-none);

    @include mq('large') {
      flex-direction: row;
    }

    > div.container {
      @include mq('large') {
        flex: 1;
      }
    }

    .brand-card {
      &__content {
        @include mq('large') {
          padding-top: 61px;
          padding-right: 75px;
          padding-bottom: 62px;
          padding-left: 48px; //to match figma
          justify-content: center;
        }
      }

      &__cta {
        min-width: 250px; // as per design
      }
    }
  }
}
