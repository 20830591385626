// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// utility classes for background colors
.u-bg-color--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg-color--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg-color--medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.u-bg-color--dark-grey {
  background-color: var(--emu-semantic-colors-secondary-950);
}

.u-bg-color--beige {
  background-color: var(--emu-semantic-colors-primary-light);
}

.u-bg-color--light-brown {
  background-color: var(--emu-semantic-colors-primary-500);
}

.u-bg-color--medium-brown {
  background-color: var(--emu-semantic-colors-primary-900);
}

.u-bg-color--dark-brown {
  background-color: var(--emu-semantic-colors-primary-dark);
}

// utility classes for text colors
[class*='u-text-color'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    color: inherit;
  }
}

.u-text-color--white {
  color: var(--emu-common-colors-white);
}

.u-text-color--grey {
  color: var(--emu-semantic-colors-secondary-800);
}

.u-text-color--red {
  color: var(--emu-semantic-colors-error-50);
}

.u-text-color--green {
  color: var(--emu-semantic-colors-green-50);
}

.u-text-color--brown {
  color: var(--emu-common-colors-medium-brown-500);
}

//typography utilities
[data-component='title'][class*='u-typography--'],
[data-component='text'][class*='u-typography--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    font: inherit;
  }
}
.u-typography--h1 {
  font: var(--emu-semantic-typography-narrow-headings-xxxl);

  @include mq('medium') {
    font: var(--emu-semantic-typography-wide-headings-xxxl);
  }
}

.u-typography--h2 {
  font: var(--emu-semantic-typography-narrow-headings-xxl);

  @include mq('medium') {
    font: var(--emu-semantic-typography-wide-headings-xxl);
  }
}

.u-typography--h3 {
  font: var(--emu-semantic-typography-narrow-headings-xl);

  @include mq('medium') {
    font: var(--emu-semantic-typography-wide-headings-xl);
  }
}

.u-typography--h4 {
  font: var(--emu-semantic-typography-narrow-headings-large);

  @include mq('medium') {
    font: var(--emu-semantic-typography-wide-headings-large);
  }
}

.u-typography--h5 {
  font: var(--emu-semantic-typography-narrow-headings-medium);

  @include mq('medium') {
    font: var(--emu-semantic-typography-wide-headings-medium);
  }
}

.u-typography--body-sm {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  font-weight: var(--emu-common-font-weight-light);

  @include mq('medium') {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.u-typography--body-xs {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  font-weight: var(--emu-common-font-weight-light);

  @include mq('medium') {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-xs);
  }
}

.u-typography--body-xxs {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-weight: var(--emu-common-font-weight-light);

  @include mq('medium') {
    font-size: var(--emu-semantic-font-sizes-wide-xxs);
    line-height: var(--emu-semantic-line-heights-wide-xxs);
  }
}

// utility classes for font-families
[data-component='text'][class*='u-font-family--'],
[data-component='title'][class*='u-font-family--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  a,
  span {
    font-family: inherit;
  }
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body);
}

.u-font-family--beatrice-reg {
  font-family: var(--emu-semantic-font-families-beatrice-regular);
}

.u-font-family--beatrice-bold {
  font-family: var(--emu-semantic-font-families-beatrice-bold);
}

// utility for list without list style
.u-list-style-none ul {
  list-style: none;
}

.u-list-no-spacing ul {
  padding: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
}

// utilities for font-weights
.u-font-weight--light {
  font-weight: var(--emu-common-font-weight-light);
}

.u-font-weight--regular {
  font-weight: var(--emu-common-font-weight-regular);
}

.u-font-weight--bold {
  font-weight: var(--emu-common-font-weight-bold);
}

//utility for max width of 1440px to the container
.u-container--max {
  @include max-container;
}

.u-fancy-border {
  &::before {
    content: '';
    display: inline-block;
    width: 80px; //as per figma
    padding-bottom: 10px;
    border-top: var(--emu-common-border-width-thin) solid currentColor;

    @include mq('large') {
      padding-bottom: 25px;
      width: 180px; //as per figma
    }
  }
}

.u-hide {
  display: none !important; // makes sure that the element is not displayed

  @include aem-editor-view {
    display: block !important; // makes sure that the element is not displayed
  }
}

// utility for teasers
// utility to make desktop image of teaser as background
.u-teaser--mobile-text-inline {
  &.emu-teaser.emu-teaser-v3.inline-text-overlay {
    .teaser-container {
      @include mq('large', 'max-width') {
        display: flex;
        flex-direction: column-reverse;
      }

      .emu-teaser {
        &__description {
          @include mq('large', 'max-width') {
            padding-top: 9px;
          }
        }

        &__action-container {
          .emu-button-v2 {
            @include mq('large', 'max-width') {
              padding-left: 38px;
              padding-right: 38px;
            }
          }
        }

        &__image {
          img {
            @include mq('large', 'max-width') {
              max-height: 220px;
              object-fit: contain;
              object-position: calc(50% + 3px); // to align with the design
            }
          }
        }
      }

      .content-container {
        @include mq('large', 'max-width') {
          position: static;
          padding-bottom: 17px;
        }
      }
    }
  }
}

.u-teaser-disclaimer-text-color--dark {
  .content-container {
    .emu-teaser__disclaimer {
      p {
        color: var(--emu-semantic-colors-secondary-dark);
      }
    }
  }
}

// utility to bind two inputs in row in desktop
.u-form-2-col {
  display: flex;
  flex-direction: column;

  @include mq('medium') {
    flex-direction: row;
    gap: 17px;
  }

  .text {
    flex: 1;
  }
}

.u-form-el-stacked-in-mobile {
  @include mq('medium', 'max-width') {
    display: flex;
    flex-direction: column;

    &.emu-form-radio[data-layout='inline'],
    &.emu-form-checkbox[data-layout='inline'] {
      gap: var(--emu-common-spacing-none);
    }
  }
}

.u-scrollto-section {
  scroll-margin-top: 80px;
}

.u-svg-logo {
  svg {
    display: block;
  }
}
