.gen-header {
  .emu-navigation {
    &__content-wrapper {
      top: 70px;

      @include mq('medium') {
        position: absolute;
      }

      @include mq('large') {
        position: static;
      }

      nav {
        height: calc(100vh - 71px);
        overflow: auto;
        background-color: var(--emu-common-colors-white);

        @include mq('large') {
          height: auto;
          background-color: var(--emu-common-colors-transparent);
          overflow: initial;
        }

        a {
          padding: var(--emu-common-spacing-none);
          display: contents;
        }

        // main menu styles
        > ul {
          @include mq('medium') {
            flex-direction: column;
          }

          @include mq('large') {
            flex-direction: row;
          }

          // main menu li's
          > li.emu-navigation__item {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-medium-brown-500);

            @include mq('large') {
              border-bottom: none;
              position: relative;
            }

            &:not(:last-child) {
              @include mq('large') {
                margin-right: auto;
              }
            }

            // arrows image in desktop on hover and click
            &:hover {
              & > span::after {
                @include mq('large') {
                  content: '';
                  background-image: url('../../assets/images/caret-down-mobile.svg');
                }
              }

              &.js-open--mobile {
                // this class is added by AAAEM
                ul {
                  @include mq('large') {
                    display: block;
                  }
                }
              }
            }

            // for desktop hovering
            &::after {
              @include mq('large') {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 58px;
              }
            }

            // arrows rotate style when clicked
            &.show-submenu.js-open--mobile,
            &.show-submenu {
              & > span::after {
                transform: rotate(180deg);

                @include mq('large') {
                  transform: none;
                }
              }

              // submenu, on click should be visible only on mobile
              @include mq('large', 'max-width') {
                ul {
                  display: block;
                }
              }
            }

            // to apply show-submenu display property, need to make this class display none
            &.js-open--mobile {
              ul {
                display: none;
              }
            }

            // main menu text styles
            > span,
            > a {
              color: var(--emu-common-colors-dark-brown-500);
              border: none;
              align-items: center;
              display: flex;
              padding-left: 37px;
              padding-right: 37px;
              position: relative;
              padding-top: 18px;
              padding-bottom: 18px;
              font-size: var(--emu-semantic-font-sizes-narrow-small);
              line-height: 23.4px; //as per figma
              font-family: var(--emu-semantic-font-families-beatrice-regular);

              @include mq('large') {
                color: var(--emu-common-colors-white);
                line-height: var(--emu-semantic-sizing-one-line-height-narrow);
                padding: var(--emu-common-spacing-none);
              }

              //arrow icons mobile and desktop
              &::after {
                content: '';
                transition: all var(--emu-common-other-time-transition-short)
                  linear;
                display: inline;
                position: absolute;
                right: 37px;
                background-image: url('../../assets/images/caret-down-mobile.svg');
                background-repeat: no-repeat;
                background-size: 24px;
                height: 24px;
                width: 24px;

                @include mq('large') {
                  content: '';
                  background-image: none;
                  background-size: var(--emu-common-sizing-small);
                  height: var(--emu-common-sizing-small);
                  width: var(--emu-common-sizing-small);
                  right: 0;
                  left: 4px;
                  margin-left: auto;
                  margin-right: auto;
                  bottom: -32px;
                  transform: none;
                }
              }

              //main menu text hover state
              &:hover {
                background-color: var(--emu-common-colors-white);

                @include mq('large') {
                  background-color: var(--emu-common-colors-transparent);
                }
              }

              //contact us arrow styles
              &:last-child::after {
                content: none;
              }
            }

            &:not(.emu-navigation__item-parent) {
              > span {
                @include mq('large', 'max-width') {
                  padding: var(--emu-common-spacing-none);
                }

                a {
                  @include mq('large', 'max-width') {
                    display: block;
                    width: 100%;
                    padding-top: 18px;
                    padding-right: 37px;
                    padding-bottom: 18px;
                    padding-left: 37px;
                  }
                }
              }
            }

            > a > span {
              padding: var(--emu-common-spacing-none);
            }
          }
        }

        li + li {
          margin-top: var(--emu-common-spacing-none);
        }

        //submenu styles
        & > ul ul {
          border: none;

          @include mq('medium') {
            position: relative;
          }

          @include mq('large') {
            padding-top: 13px;
            padding-right: var(--emu-common-spacing-none);
            padding-bottom: var(--emu-common-spacing-medium);
            padding-left: var(--emu-common-spacing-none);
            border-top: var(--emu-common-border-width-thick) solid
              var(--emu-common-colors-dark-brown-500);
            top: 62px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            width: 272px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
          }

          //submenu links
          & > li {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-white);
            background-color: var(--emu-common-colors-light-grey-500);

            // submenu text styles
            & > a {
              //submenu text description styles
              font-family: var(--emu-semantic-font-families-body);
              font-size: var(--emu-semantic-font-sizes-narrow-xxs);
              line-height: var(--emu-semantic-line-heights-narrow-xxs);
              font-weight: var(--emu-common-font-weight-light);
              padding-top: 18px;
              padding-right: 37px;
              padding-bottom: 17px;
              padding-left: 37px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: var(--emu-common-spacing-small);
              font-size: var(--emu-semantic-font-sizes-narrow-xxs);
              line-height: var(--emu-semantic-line-heights-narrow-xxs);

              @include mq('large') {
                background-color: var(--emu-common-colors-white);
                line-height: var(--emu-semantic-line-heights-narrow-xs);
                padding-top: var(--emu-common-spacing-medium);
                padding-bottom: var(--emu-common-spacing-medium);
                padding-left: var(--emu-common-spacing-large);
                padding-right: var(--emu-common-spacing-large);
              }

              //submenu hover state in desktop
              &:hover {
                background-color: var(--emu-common-colors-light-grey-500);
              }

              > span {
                padding: var(--emu-common-spacing-none);

                @include mq('large') {
                  line-height: 20px;
                }
              }

              //submenu link titles styles
              b {
                font-family: var(--emu-semantic-font-families-heading);
                font-size: var(--emu-common-font-sizes-wide-medium);
                font-weight: var(--emu-common-font-weight-light);
                line-height: 20.8px; //as per figma
                display: block;
                margin-bottom: 9px;

                @include mq('large') {
                  line-height: 12px;
                  margin-bottom: 7px;
                }
              }

              a {
                @include mq('large') {
                }
              }
            }
          }
        }
      }
    }
  }

  //on scroll background change styles
  &--background {
    .emu-navigation {
      &__content-wrapper {
        nav {
          & > ul {
            & > li.emu-navigation__item {
              //in desktop only on hover and click arrows should be visible
              &:hover,
              &.show-submenu {
                & > span {
                  &::after {
                    @include mq('large') {
                      content: '';
                      background-image: url('../../assets/images/caret-down-desktop.svg');
                      transform: none;
                    }
                  }
                  &:last-child::after {
                    content: none;
                  }
                }
              }
            }
            ul {
              top: 63px;
            }
          }
        }
      }
    }
  }
}
