@import '../../styles/global/_g-utilities';

.emu-form {
  transition: padding 0.2s ease-out;
  position: relative;

  &--loading {
    transition: padding 0.2s ease-in;
    padding-bottom: 140px;

    .emu-form__loader {
      opacity: 1;
    }
  }

  &__loader {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in;

    svg {
      animation-duration: 1s;
    }
  }

  &-text {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-medium);
    width: 100%;

    &__input {
      width: 100%;
      height: 60px; // as per figma

      &:disabled::placeholder {
        background-color: var(--emu-common-colors-light-grey-500);
      }

      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        display: none;
      }
    }

    &__info-text,
    &__error-text {
      @extend .u-typography--body-xxs;
    }
  }

  &-textarea {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 18px;
    width: 100%;

    &__container,
    &__textarea {
      width: 100%;
    }

    &__textarea {
      resize: none;
    }

    &__label {
      margin-bottom: 5px;
      line-height: 25px;
    }

    &__error-text {
      @extend .u-typography--body-xxs;
    }
  }

  &-recaptcha {
    margin-top: var(--emu-common-spacing-medium);
    margin-bottom: 22px;

    @include mq('large') {
      margin-bottom: 34px;
    }

    &__error-text {
      @extend .u-typography--body-xxs;
    }
  }

  .emu-form-dropdown .emu-dropdown-menu__group {
    max-height: 232px; //to limit the height of dropdown
    overflow-y: auto;
  }

  .emu-form-dropdown:not(.js-hide-dropdown):not([data-multiple])
    .emu-dropdown-menu__group {
    border-top-width: var(--emu-common-border-width-thin);
  }

  &-dropdown {
    position: relative;
    margin-top: var(--emu-common-spacing-none);

    .emu-dropdown-menu {
      &__button {
        width: 100% !important; // to override width from AEM

        svg {
          width: 25px;
          height: 20px;
          flex-shrink: 0;
        }

        &:disabled {
          background-color: var(--emu-common-colors-light-grey-500);
          color: var(--emu-common-colors-medium-grey-500);
          border-color: var(--emu-common-colors-medium-grey-500);

          svg {
            fill: var(--emu-common-colors-medium-grey-500);
          }
        }

        & span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      &__group {
        width: 100% !important; //to override width from AEM

        @include mq('medium') {
          min-width: 240px !important;
        }

        li[data-value='--'] {
          display: none;
        }

        li:first-child {
          border-top-width: var(--emu-common-border-width-none);
        }

        li + li {
          margin-top: var(--emu-common-spacing-none);
        }
      }
    }

    &__info-text {
      @extend .u-typography--body-sm;
    }

    &__error-text {
      @extend .u-typography--body-xxs;
    }
  }

  &-radio {
    display: flex;

    &[data-layout='inline'] {
      column-gap: var(--emu-common-spacing-xl);
      flex-wrap: wrap;
    }

    &[data-layout='stacked'] {
      gap: var(--emu-common-spacing-medium);
      flex-direction: column;
    }

    &__info-text {
      @extend .u-typography--body-sm;
    }

    &__error-text {
      @extend .u-typography--body-xxs;
      flex-basis: 100%;
    }
  }

  &-checkbox {
    display: flex;

    &[data-layout='inline'] {
      column-gap: var(--emu-common-spacing-xl);
      flex-wrap: wrap;
    }

    &[data-layout='stacked'] {
      gap: var(--emu-common-spacing-medium);
      flex-direction: column;
    }

    &__info-text {
      @extend .u-typography--body-sm;
    }

    &__label {
      span {
        flex: 1;
      }
    }

    &__error-text {
      @extend .u-typography--body-xxs;
      flex-basis: 100%;
    }
  }

  &__message {
    padding-top: 30px;
    padding-bottom: 30px;
    border: var(--emu-common-border-width-thin) solid;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    margin-top: var(--emu-common-spacing-large);

    @include mq('medium') {
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      & + p {
        margin-top: var(--emu-common-spacing-medium);
        letter-spacing: -0.16px; // to match figma

        @include mq('large') {
          letter-spacing: normal;
        }
      }
    }

    .emphasis {
      @extend .u-typography--h5;
      @extend .u-font-family--beatrice-reg;
      margin-bottom: var(--emu-common-spacing-medium);
    }

    &--success {
      border-color: var(--emu-semantic-colors-green-50);
      background: rgba(76, 175, 80, 0.05);
    }

    &--error {
      border-color: var(--emu-semantic-colors-error-50);
      background: rgba(235, 0, 0, 0.05);
    }

    &--api-response {
      margin-top: var(--emu-common-spacing-large);
    }
  }

  .js-has-error {
    .emu-form-text__input,
    .emu-form-textarea__textarea,
    .emu-dropdown-menu__button {
      background-color: var(
        --emu-component-ingredients-text-input-color-background-error-light
      );
      border-color: var(
        --emu-component-ingredients-text-input-color-border-error-light
      );
      color: var(
        --emu-component-ingredients-text-input-label-text-color-error-light
      );
    }
  }
}
