[data-component='embed'] {
  .plyr {
    display: flex;
    justify-content: center;
    align-items: center;

    &__control {
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

      &--overlaid {
        width: 50px;
        height: 50px;
        background-image: url('../../assets/images/play_button-mobile.svg');
        background-color: var(--emu-common-colors-transparent);
        top: auto;
        left: auto;
        bottom: -4px;
        right: -5px;

        @include mq('large') {
          width: 80px;
          height: 80px;
          bottom: -8px;
          right: -8px;
          background-image: url('../../assets/images/play_button.svg');
        }

        &:hover {
          background-image: url('../../assets/images/play_button-mobile.svg'); //to override default
          background-color: var(--emu-common-colors-transparent);

          @include mq('large') {
            background-image: url('../../assets/images/play_button.svg'); //to override default
          }
        }

        svg {
          display: none;
        }
      }
    }

    &--full-ui {
      .plyr__video-embed {
        .plyr__video-embed__container {
          transform: translateY(0);
        }
      }
    }

    &--stopped,
    &--paused {
      .plyr__controls {
        visibility: hidden;
      }
    }
  }
}
