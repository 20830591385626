.page-intro {
  &__wrapper {
    padding-top: var(--emu-common-spacing-large);
    padding-right: 46px; //to match figma
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: 37px;

    @include mq('large') {
      display: flex;
      padding-top: 66px; //to match figma
      padding-right: 137px;
      padding-left: 135px;
      justify-content: space-between;
    }

    > .container {
      &:first-child {
        @include mq('large') {
          margin-right: var(--emu-common-spacing-medium);
        }
      }
    }
  }

  &__title-wrapper {
    @include mq('large') {
      max-width: 340px; //as per figma
      width: 100%;
    }
  }

  &__title {
    .cmp-title__text {
      margin-bottom: var(--emu-common-spacing-small);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }
  }

  &__subtitle {
    .cmp-title__text {
      margin-bottom: var(--emu-common-spacing-large);
    }
  }

  &__content-wrapper {
    @include mq('large') {
      max-width: 812px;
    }
  }

  &__text {
    p {
      font-weight: inherit;
      padding-right: var(--emu-common-spacing-xs);

      @include mq('large') {
        padding-right: var(--emu-common-spacing-none);
      }
    }

    &.cmp-text {
      .emphasis {
        display: block;
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }

    i {
      letter-spacing: -0.32px;
    }

    ul {
      padding-left: 23px; //to match figma
      margin-bottom: 13px; //to match figma
      list-style: none;
      margin-top: var(--emu-common-spacing-none);

      @include mq('large') {
        margin-bottom: 14px; //to match figma
      }
    }

    li {
      margin-top: var(--emu-common-spacing-none);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: -10px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: currentColor;
      }
    }
  }

  &__content-block {
    > .button {
      .emu-button-v2.emu-button-v2 {
        padding-top: var(--emu-common-spacing-medium);
        padding-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          padding-left: 13px;
        }
      }
    }

    &-spacing-block {
      padding-bottom: var(--emu-common-spacing-large);
      border-bottom: var(--emu-common-border-width-medium) solid
        var(--emu-semantic-colors-primary-500);
      margin-bottom: var(--emu-common-spacing-large);
    }
  }

  &__content-block-title {
    margin-bottom: var(--emu-common-spacing-medium);
  }

  &__content-block-subtitle {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-small);
    }
  }

  &__cta {
    width: 100%;
    justify-content: center;
    margin-top: 30px;

    @include mq('large') {
      margin-top: 31px;
      max-width: 340px; // as per design
    }
  }

  &--with-logo {
    .page-intro__wrapper {
      @include mq('large') {
        padding-right: 160px; // as per design
      }
    }

    .page-intro__img {
      @include mq('large', 'max-width') {
        margin-top: var(--emu-common-spacing-large);
      }

      svg {
        display: block;
      }
    }
  }

  &__cta {
    &.emu-button-v2.emu-button-v2.emu-button-v2 {
      margin-top: var(--emu-common-spacing-large);
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
      padding-right: 47px;
      padding-left: 47px;
    }

    &-container {
      padding-left: 37px;
      padding-bottom: var(--emu-common-spacing-xl);

      @include mq('large') {
        padding-left: 135px;
      }
    }
  }
}
