.carousel {
  [data-component='carousel-v2'] {
    .tns-nav {
      padding-top: 23px;
      left: 46.9%; //to match the design

      @include mq('large') {
        padding-top: 24px;
        left: 48.15%; //to match the design
      }

      button {
        display: block !important; //to handle inline style of display:none added by tiny-slider
        
        &,
        &.tns-nav-active {
          background-color: var(
            --emu-component-containers-carousel-indicators-dots-color-background-default-light
          );
          border-color: var(
            --emu-component-containers-carousel-indicators-dots-color-border-default-light
          );
        }

        &.js-nav-active {
          background-color: var(
            --emu-component-containers-carousel-indicators-dots-color-background-active-light
          );
          border-color: var(
            --emu-component-containers-carousel-indicators-dots-color-border-active-light
          );
        }
      }
    }

    .tns-carousel {
      &.transform-none {
        transform: none !important; // to override in ios for full screen
      }
    }

    .emu-carousel {
      &__action {
        border-radius: 50%;
        width: 50px; // to match the design
        height: 50px;

        @include mq('large') {
          width: 80px; // to match the design
          height: 80px;
        }

        &::after {
          content: '';
          background-image: url('../../assets/images/arrow-button.svg');
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          width: 100%;
          height: 100%;
          background-size: cover;
        }

        .emu-carousel__action-icon {
          display: none;
        }

        &-prev {
          &::after {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }

        &:disabled {
          border-color: var(--emu-common-colors-beige-500);

          &::after {
            background-image: url('../../assets/images/arrow-button-disabled.svg');
          }

          &.emu-carousel__action-prev {
            &::after {
              transform: translate(-50%, -50%) rotate(0deg);
            }
          }

          &.emu-carousel__action-next {
            &::after {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }
      }
    }
  }
}
