#getting-started {
  .teaser {
    .emu-teaser.emu-teaser-v3.inline-text-below.u-teaser--as-hero {
      .content-container {
        padding-top: 25px; // to match figma

        @include mq('large') {
          padding-top: 35px; // to match figma
        }
      }
    }
  }

  .page-intro {
    &__wrapper {
      padding-right: 37px;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-xl);
      }

      > .container {
        &:first-child {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__title {
      .cmp-title__text {
        color: var(--emu-semantic-colors-primary-900);
      }
    }

    &__content-wrapper {
      @include mq('large') {
        max-width: 808px; // as per figma
      }
    }
  }

  .intro-group {
    .page-intro {
      &__wrapper {
        padding: var(--emu-common-spacing-none);
      }

      &__title {
        margin-bottom: 18px;

        .cmp-title__text {
          line-height: 41px; // to match figma

          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            white-space: nowrap;
            margin-right: var(--emu-common-spacing-large);
          }
        }
      }

      &__text {
        p {
          line-height: 25px; // to match figma
          letter-spacing: 0.01px; // to match figma

          @include mq('large') {
            letter-spacing: normal;
          }

          & + p {
            margin-top: 15px; // to match figma
          }
        }

        ul {
          margin-top: var(--emu-common-spacing-small);
          margin-bottom: var(--emu-common-spacing-medium);
          padding-left: 27px; // to match figma

          @include mq('large') {
            padding-left: 26px;
          }
        }

        li {
          @include mq('large') {
            line-height: 25px; // to match figma
          }

          &::before {
            left: -16px; // to match figma
          }
        }
      }

      &__content-wrapper {
        @include mq('large') {
          margin-top: var(--emu-common-spacing-small);
          margin-right: var(--emu-common-spacing-small);
        }
      }

      &__content-block-spacing-block {
        padding-bottom: var(--emu-common-spacing-none);
        border-bottom: none;
        margin-top: var(--emu-common-spacing-large);
        margin-bottom: var(--emu-common-spacing-none);
      }

      &__content-block-subtitle {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-small);

        .cmp-title__text {
          @include mq('large') {
            line-height: 31px; // to match figma
          }
        }
      }

      .text-cta {
        padding-top: var(--emu-common-spacing-large);
        padding-right: 29px;
        padding-bottom: var(--emu-common-spacing-large);
        padding-left: 29px;
        margin-top: var(--emu-common-spacing-medium);

        @include mq('large') {
          padding-left: var(--emu-common-spacing-large);
          padding-right: var(--emu-common-spacing-large);
          display: flex;
        }

        &__txt {
          p {
            line-height: 25px; // to match figma

            & + p {
              margin-top: 10px;
              letter-spacing: -0.12px; // to match figma

              @include mq('large') {
                letter-spacing: normal;
              }
            }

            a {
              color: var(--emu-semantic-colors-primary-dark);
            }
          }
        }

        .button {
          @include mq('large') {
            flex-shrink: 0;
            margin-left: var(--emu-common-spacing-large);
          }

          .emu-button-v2 {
            &.emu-button__primary-outline {
              min-height: 54px; // as per figma
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }

          .text-cta__cta {
            max-width: 228px; // as per figma
            width: 100%;
            margin-top: var(--emu-common-spacing-large);
            justify-content: center;
            align-items: center;

            @include mq('large') {
              min-width: 230px; // as per figma
              max-width: none;
              margin-top: var(--emu-common-spacing-none);
            }
          }
        }

        &--volbella {
          .text-cta__txt {
            p {
              & + p {
                margin-top: var(--emu-common-spacing-medium);

                @include mq('large') {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }

    &__item-spacing-block {
      padding-bottom: 61px;

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-xl);
      }

      &--step-3,
      &--step-5 {
        @include mq('large') {
          padding-bottom: 62px;
        }
      }

      &--step-4 {
        padding-bottom: 63px;
      }
    }

    .greeting-contact {
      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }

      .cmp-title__text {
        @include mq('large', 'max-width') {
          line-height: 34px; // to match figma
        }

        i {
          font-style: normal;
          display: inline-block;
        }
      }

      .cmp-text {
        margin-top: var(--emu-common-spacing-large);

        a {
          color: var(--emu-semantic-colors-primary-dark);
        }
      }
    }
  }
}
