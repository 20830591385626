// to override the styles added to text-theme-light in the teaser
.text-theme-light .button .emu-button-v2.emu-button-v2 {
  &.emu-button__secondary-outline.emu-button {
    background-color: var(
      --emu-component-actions-button-color-background-secondary-outline-default-light
    );
    border-color: var(
      --emu-component-actions-button-color-border-secondary-outline-default-light
    );
    border-radius: var(
      --emu-component-actions-button-border-radius-secondary-outline
    );
    border-width: var(
      --emu-component-actions-button-border-width-secondary-outline
    );
    color: var(
      --emu-component-actions-button-text-color-secondary-outline-default-light
    );
    outline-color: var(
      --emu-component-actions-button-color-outline-color-secondary-outline-default-light
    );

    &:focus {
      background-color: var(
        --emu-component-actions-button-color-background-secondary-outline-focus-light
      );
      border-color: var(
        --emu-component-actions-button-color-border-secondary-outline-focus-light
      );
      color: var(
        --emu-component-actions-button-text-color-secondary-outline-focus-light
      );
      outline-color: var(
        --emu-component-actions-button-color-outline-color-secondary-outline-focus-light
      );
    }

    &:hover {
      background-color: var(
        --emu-component-actions-button-color-background-secondary-outline-hover-light
      );
      border-color: var(
        --emu-component-actions-button-color-border-secondary-outline-hover-light
      );
      color: var(
        --emu-component-actions-button-text-color-secondary-outline-hover-light
      );
      outline-color: var(
        --emu-component-actions-button-color-outline-color-secondary-outline-hover-light
      );
    }

    &:active {
      background-color: var(
        --emu-component-actions-button-color-background-secondary-outline-active-light
      );
      border-color: var(
        --emu-component-actions-button-color-border-secondary-outline-active-light
      );
      color: var(
        --emu-component-actions-button-text-color-secondary-outline-active-light
      );
      outline-color: var(
        --emu-component-actions-button-color-outline-color-secondary-outline-active-light
      );
      outline-style: solid;
      outline-width: var(
        --emu-component-actions-button-outline-width-secondary-outline
      );
    }
  }
}
