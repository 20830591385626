html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden !important; // making sure to override any styles added by AAAEM
  font: var(--emu-semantic-typography-narrow-body-regular);

  @include mq('large') {
    font: var(--emu-semantic-typography-narrow-body-regular);
  }
}

main {
  overflow: hidden;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
button {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  color: inherit;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

p,
li {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}

p + p,
li + li {
  margin-top: 13px;
}

sup {
  vertical-align: super;
  font-size: 60%; // to match figma
  font-weight: inherit;
  line-height: 0;
  position: relative;
  top: 0.03em; // to match figma
}

.cmp-title__text {
  sup {
    font-size: 100%; // to match figma
    top: 0.35em; // to match figma
  }
}

i {
  sup {
    left: 0.05em; // to match figma
  }
}

// making sure that the ISI stays hidden when footer is in view
.footer-in-view {
  [data-component='container-isi-v2'] .isi-container {
    transform: translateY(100%);
  }
}

.mobile-menu-opened {
  @include mq('large', 'max-width') {
    overflow: hidden;
  }
}

.emu-consent-banner {
  > div {
    max-width: 1170px;
  }
}