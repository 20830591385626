.title-hero {
  &__inner {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
    padding-right: 37px;
    padding-left: 37px;

    @include mq('large') {
      padding-top: 203px;
      padding-bottom: 137px;
      padding-left: 135px;
    }
  }

  &__main {
    &.u-fancy-border::before {
      @include mq('large') {
        padding-bottom: 27px;
      }
    }
  }

  &__sub {
    padding-top: var(--emu-common-spacing-none);
    color: var(--emu-semantic-colors-primary-light);

    @include mq('large') {
      padding-top: 10px;
    }
  }
}
