.catalog {
  &__inner {
    padding-top: var(--emu-common-spacing-xl);
    padding-bottom: var(--emu-common-spacing-xl);
    padding-left: var(--emu-common-spacing-small);

    @include mq('large') {
      padding-left: 15px;
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-xl);
  }

  &__section {
    &-title {
      margin-bottom: 18px;
      padding-right: var(--emu-common-spacing-small);

      @include mq('large') {
        padding-right: var(--emu-common-spacing-medium);
        margin-bottom: var(--emu-common-spacing-medium);
      }
    }

    &-subtitle {
      margin-bottom: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-small);

      @include mq('large') {
        max-width: 1171px; //as per design
        padding-right: var(--emu-common-spacing-medium);
      }
    }
  }

  &__items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: var(--emu-common-spacing-xl);

    @include mq('large') {
      justify-content: left;
    }

    > .container {
      margin-right: var(--emu-common-spacing-small);
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-right: var(--emu-common-spacing-medium);
      }

      &:last-child {
        margin-bottom: var(--emu-common-spacing-none);
        
        @include mq('large', 'max-width') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }
    }
  }

  &__item {
    width: 100%;
    max-width: 344px; //as per design
    min-height: 330px; //as per design
    padding-top: 42px;
    padding-right: 29px;
    padding-bottom: 42px;
    padding-left: 29px;

    @include mq('large') {
      max-width: 339px;  // to match figma
    }

    @include mq('xx-large') {
      max-width: 340px; //as per design
    }
  }

  &__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 111px; //to match figma
    margin-bottom: 31px;

    @include mq('large') {
      margin-bottom: 34px;
      height: 106px; //to match figma
    }

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__item-txt {
    @include mq('large') {
      padding-left: var(--emu-common-spacing-xxs);
      padding-right: var(--emu-common-spacing-xxs);
    }

    p {
      font-weight: inherit;
    }
  }

  &__item-txt-volume {
    margin-top: 10px;

    @include mq('large') {
      padding-left: var(--emu-common-spacing-xxs);
      padding-right: var(--emu-common-spacing-xxs);
    }
  }

  &__disc-txt {
    max-width: 1171px; //as per design
    padding-right: 9px;

    @include mq('large') {
      padding-right: var(--emu-common-spacing-none);
    }

    p {
      & + p {
        @include mq('large') {
          margin-top: 10px; //to match figma
        }
      }
    }
  }

  &__kit {
    &-section {
      margin-top: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-small);

      @include mq('large') {
        display: flex;
        padding-right: var(--emu-common-spacing-none);
        gap: 42px;
      }
    }

    &-img {
      margin-bottom: 15px;

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    &-desc {
      @include mq('large') {
        width: 895px; //as per figma
      }
    }
  }
}
