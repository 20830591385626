.gen-footer {
  &__inner {
    padding-top: var(--emu-common-spacing-large);
    padding-right: 37px;
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 37px;

    @include mq('large') {
      padding-top: 34px;
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 34px;
      padding-left: var(--emu-common-spacing-medium);
    }

    > .container {
      @include mq('large') {
        @include max-container;
      }
    }
  }

  &__top-section {
    @include mq('large') {
      display: flex;
    }

    .image {
      @include mq('large') {
        margin-right: auto;
      }
    }
  }

  &__links {
    margin-top: var(--emu-common-spacing-large);
    margin-bottom: 34px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    ul {
      display: flex;
      flex-direction: column;

      @include mq('large') {
        flex-direction: row;
      }
    }

    li {
      margin-top: var(--emu-common-spacing-none);

      &:not(:last-child) {
        margin-bottom: 11.5px; //to match figma

        @include mq('large') {
          margin-right: var(--emu-common-spacing-large);
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq('x-large') {
          margin-right: var(--emu-common-spacing-xl);
        }
      }
    }

    a {
      text-decoration: none;
      line-height: 19.2px;
    }
  }

  &__bottom-section {
    &.aaaem-container {
      @include mq('large') {
        margin-top: 33px;
      }
    }
  }

  &__copyright {
    p {
      &:not(:first-child) {
        @include mq('large') {
          margin-top: 11px; //to match figma
        }
      }
    }
  }
}
